import hamburger from '../../../assets/hamburger.svg'
import { useStores } from '../../stores'
import './MenuButton.scss'
import { observer } from 'mobx-react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export default observer(function MenuButton() {
  const { commonStore } = useStores()
  const { t } = useTranslation(['menu'], {
    useSuspense: true,
  })

  const openMenu = (event) => {
    console.log('open menu')
    commonStore.setSideMenuOpen(true)
    //closing the menu is handled by the useClickOutside hook at SideMenu.jsx
  }

  return (
    commonStore.showSideMenu && (
      <div
        className={
          'menu-button ' + (commonStore.sideMenuOpened ? 'disabled' : '')
        }
        role='button'
        onClick={
          !commonStore.sideMenuOpened
            ? openMenu
            : (event) => {
                event.stopPropagation()
              }
        }
        data-label={t('buttonLabels.menu')}
      >
        <img
          src={hamburger}
          className={
            'menu-icon ' + (commonStore.sideMenuOpened ? 'opened' : '')
          }
          alt='menu'
        />
      </div>
    )
  )
})
