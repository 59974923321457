import { useStores } from '../../stores'
import './LinkGroupIndicatorContainer.scss'
import { LinkIndicator } from './LinkIndicator'
import { observer } from 'mobx-react'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export default observer(function LinkGroupIndicatorContainer(props) {
  const { t } = useTranslation(['common', 'board'], {
    useSuspense: true,
  })
  const { commonStore, boardStore } = useStores()
  const { myCompetenceId, onIndicatorClick } = props

  const onClick = useCallback(
    (evt) => {
      let groupId = evt.currentTarget.getAttribute('data-id')
      let position = {
        x: evt.currentTarget.offsetLeft,
        y: evt.currentTarget.offsetTop,
      }
      console.log(myCompetenceId, groupId)

      console.log()
      //alert('select comptences here')
      onIndicatorClick(groupId, position)
    },
    [myCompetenceId, onIndicatorClick]
  )

  return (
    <div className='link-status-indicator-container'>
      {commonStore.standardCompetences.map((item, index) => {
        return (
          <LinkIndicator
            key={index}
            tt={t('board:tooltips.linkSubcompetences')}
            linked={boardStore.getGroupLinkedStatus(myCompetenceId, item.id)}
            onClick={onClick}
            data={item}
            {...props}
          />
        )
      })}
    </div>
  )
})
