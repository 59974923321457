export function BigBottomButton({
  onClick = () => {},
  tt = '',
  label = '',
  svgIcon,
  buttonClass = '',
  disabled = false,
  ...props
}) {
  return (
    <div
      role='button'
      title={tt}
      className={'btn-dcp ' + buttonClass + (disabled ? ' disabled' : '')}
      onClick={onClick}
      {...props}
    >
      <div className='btn-dcp-inner'>
        {label && <div className='btn-dcp-label'>{label}</div>}{' '}
        {svgIcon && (
          <div className='btn-dcp-hover-icon'>
            <img src={svgIcon} alt={label} />
          </div>
        )}
      </div>
    </div>
  )
}
