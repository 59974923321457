import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { BigBottomButton } from './BigBottomButton'
import './TinyPopup.scss'

export default observer(function TinyPopup(props) {
  const { t } = useTranslation(['common', 'board'], {
    useSuspense: true,
  })
  const {
    onClose,
    onConfirm,
    confirmLabel,
    cancelLabel,
    popupClass = '',
  } = props
  return (
    <div className='tinypopup-container'>
      <div className='tinypopup-container-background' onClick={onClose}></div>
      <div className={'tinypopup ' + popupClass} role='button'>
        <div className='tinypopup-body'>{props.children}</div>
        <div className='tinypopup-footer'>
          <div className='btn-group'>
            {confirmLabel && (
              <BigBottomButton
                onClick={onConfirm}
                buttonClass={'xs primary'}
                label={confirmLabel}
              />
            )}
            {cancelLabel && (
              <BigBottomButton
                onClick={onClose}
                buttonClass={'xs'}
                label={cancelLabel}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
})
