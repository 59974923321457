import { configure } from 'mobx'

import React, { useContext } from 'react'

import { WebService } from '../services/WebService'

import { CommonStore } from './commonStore'
import { BoardStore } from './boardStore'
import { PopupMessageStore } from './popupMessageStore'
import { WalkthroughStore } from './walkthroughStore'

configure({ enforceActions: 'observed' })

const webservice = WebService.getInstance()
const commonStore = new CommonStore(webservice)
const walkthroughStore = new WalkthroughStore(commonStore)
const popupMessageStore = new PopupMessageStore(commonStore, webservice)
const boardStore = new BoardStore(commonStore, webservice, popupMessageStore)

export const stores = {
  commonStore,
  boardStore,
  popupMessageStore,
  walkthroughStore,
}

export const StoresContext = React.createContext(stores)

export function StoreProvider(props) {
  const { children } = props

  return (
    <StoresContext.Provider value={stores}>{children}</StoresContext.Provider>
  )
}

export function withStores(WrappedComponent) {
  return function WithStores(props) {
    const providedStores = useStores()

    return <WrappedComponent {...props} {...providedStores} />
  }
}

export function useStores() {
  return useContext(StoresContext)
}
