import { useStores } from '../../stores'
import { POPUP_TYPE } from '../../utils/Enums'
import { stripHtml } from '../../utils/stripHtml'
import { BigBottomButton } from './BigBottomButton'
import './PopupDcp.scss'
import './PopupNewCompetence.scss'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const MAX_LENGTH = 450

export default observer(function PopupNewCompetence(props) {
  const { boardStore, popupMessageStore, walkthroughStore } = useStores()
  const { data } = props
  const { t, ready } = useTranslation(['common', 'board', 'popup'], {
    useSuspense: true,
  })

  const [inputText, setInputText] = useState(data.myCompetenceData?.body ?? '')
  const [charCount, setCharCount] = useState(MAX_LENGTH - inputText.length)

  const [type] = useState(
    data.type === POPUP_TYPE.NEW_COMPETENCE ? 'new' : 'edit'
  )

  const isFormComplete = useCallback(() => {
    return inputText !== ''
  }, [inputText])

  const onSubmit = useCallback(() => {
    //console.log('submit', { inputText })
    switch (data.type) {
      case POPUP_TYPE.NEW_COMPETENCE:
        boardStore.createNewMyCompetence(stripHtml(inputText)).then(() => {
          popupMessageStore.clearPopupMessage()

          walkthroughStore.nextStep()
        })
        break
      case POPUP_TYPE.EDIT_COMPETENCE:
        boardStore
          .updateMyCompetence(data.myCompetenceData.id, stripHtml(inputText))
          .then(() => {
            popupMessageStore.clearPopupMessage()
          })
        break
      default:
        alert('unknown popuptype')
    }
  }, [boardStore, data, inputText, popupMessageStore, walkthroughStore])

  const onTextChange = useCallback(
    (e) => {
      setInputText(e.target.value)

      setCharCount(e.target.maxLength - e.target.value.length)
    },
    [setInputText]
  )

  useEffect(() => {
    walkthroughStore.pause()
    return () => {
      walkthroughStore.resume()
    }
  })

  if (ready) {
    return (
      <div className='popup-new-competence-container dcp-popup'>
        <div className='row justify-content-center'>
          <div className='col'>
            <h5
              className='c2a-label'
              dangerouslySetInnerHTML={{
                __html: t('popup:' + type + 'Competence.title'),
              }}
            ></h5>{' '}
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-12'>
            <form>
              <div className='form-group'>
                <label htmlFor='inputTextFormControl'>
                  {t('popup:' + type + 'Competence.form.input.text.label')}
                </label>
                <textarea
                  maxLength={MAX_LENGTH}
                  className='form-control ta20rem'
                  onChange={onTextChange}
                  id='inputTextFormControl'
                  rows='8'
                  value={inputText}
                  placeholder={t(
                    'popup:' + type + 'Competence.form.input.text.placeholder'
                  )}
                ></textarea>
                <div className='charcount'>{charCount}</div>
              </div>
            </form>
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-12'>
            <div className='dcp-popup-button-footer'>
              <BigBottomButton
                onClick={isFormComplete() ? onSubmit : () => {}}
                buttonClass='big-confirm-button '
                label={
                  data.type === POPUP_TYPE.NEW_COMPETENCE
                    ? t('common:buttonLabels.add')
                    : t('common:buttonLabels.save')
                }
                disabled={!isFormComplete()}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return null
})
