import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useStores } from '../../stores'
import { copyUrlToClipboard } from '../../utils/copyUrlToClipboard'

export default observer(function BoardOptions(props) {
  const { t } = useTranslation(['common', 'board'])
  const { boardStore } = useStores()

  return (
    <div className='board-option-container'>
      <a
        id='pdf-link'
        href={boardStore.getPdfURL()}
        target='_blank'
        rel='noreferrer'
        className='btn btn-primary-dcp btn-sm me-4'
        title={t('board:tooltips.exportPDF')}
      >
        {t('common:buttonLabels.exportBoard')}
      </a>
      <button
        id='copy-url-button'
        className='btn btn-secondary-dcp btn-sm'
        title={t('board:tooltips.copyURL')}
        onClick={copyUrlToClipboard}
      >
        {t('common:buttonLabels.copyUrl')}
      </button>
    </div>
  )
})
