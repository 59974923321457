import { useStores } from '../../stores'
import { copyUrlToClipboard } from '../../utils/copyUrlToClipboard'
import useClickOutside from '../../utils/useClickOutside'
import './SideMenu.scss'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { WALKTHROUGH_CONTENT_TYPE } from '../walkthrough/joyride'
import { CloseButton } from './CloseButton'

import iconCopy from './../../../assets/icon-duplicate.svg'
import iconPdf from './../../../assets/icon-pdf-white.svg'
import iconReplay from './../../../assets/icon-replay-white.svg'

export default observer(function SideMenu(props) {
  const { commonStore, walkthroughStore, boardStore } = useStores()
  const { t } = useTranslation(['menu', 'common'], {
    useSuspense: true,
  })

  const { ref, setIsClickedOutside, setClickOutsideEnabled } = useClickOutside(
    () => {
      commonStore.setSideMenuOpen(false)
    },
    false
  )
  const onClose = () => {
    console.log('close menu')
    commonStore.setSideMenuOpen(false)
  }

  useEffect(() => {
    if (!commonStore.sideMenuOpened) {
      setIsClickedOutside(false)
    }
  }, [commonStore.sideMenuOpened, setIsClickedOutside])

  useEffect(() => {
    if (commonStore.sideMenuOpened) {
      setClickOutsideEnabled(true)
    } else {
      setClickOutsideEnabled(false)
    }
  }, [commonStore.sideMenuOpened, setClickOutsideEnabled])

  return (
    commonStore.showSideMenu && (
      <nav
        ref={ref}
        className={
          'sidemenu-container ' +
          (commonStore.sideMenuOpened ? 'open' : 'closed')
        }
      >
        <ul className='sidemenu-items'>
          <div className='sidemenu-header'>
            <div className='title'>Menu</div>
            <CloseButton onClose={onClose} />
          </div>

          <li
            role='button'
            className='flex-wrap emphasis'
            onClick={copyUrlToClipboard}
          >
            <div className='label-container'>
              {t('buttonLabels.menuitems.copyUrl')}
              <div className='url'>{window.location.href}</div>
            </div>
            <img src={iconCopy} alt='' />
          </li>
          <div className='item-subtext'>
            {t('buttonLabels.explain.copyUrl')}
          </div>
          <a href={boardStore.getPdfURL()} target='_blank' rel='noreferrer'>
            <li role='button' className='btn-black'>
              {t('buttonLabels.menuitems.exportBoard')}
              <img src={iconPdf} alt='' />
            </li>
          </a>
          <div className='item-subtext'>
            {t('buttonLabels.explain.exportBoard')}
          </div>
          <li
            role='button'
            className='btn-c2a'
            onClick={() => {
              commonStore.setSideMenuOpen(false)
              walkthroughStore.clearVisited()
              walkthroughStore.setVisited(WALKTHROUGH_CONTENT_TYPE.MENU)
              walkthroughStore.start(WALKTHROUGH_CONTENT_TYPE.BOARD)
            }}
          >
            {t('buttonLabels.menuitems.startTour')}
            <img src={iconReplay} alt='' />
          </li>
          <div className='item-subtext'>
            {t('buttonLabels.explain.startTour')}
          </div>
          <li
            role='button'
            onClick={() => {
              walkthroughStore.start(WALKTHROUGH_CONTENT_TYPE.REFLECT)
            }}
          >
            {t('buttonLabels.menuitems.reflection')}
          </li>
          <div className='item-subtext'>
            {t('buttonLabels.explain.reflection')}
          </div>
        </ul>
      </nav>
    )
  )
})
