import { useState, useEffect, useRef, useCallback } from 'react'

export default function useClickOutside(callBack, enable) {
  const [enabled, setEnabled] = useState(enable ?? false)
  const [isClickedOutside, setIsClickedOutside] = useState(true)
  const ref = useRef(null)

  const handleClickOutside = useCallback(
    (event) => {
      if (enabled) {
        console.log('handleCLickOutside', event)
        if (ref.current && !ref.current.contains(event.target)) {
          setIsClickedOutside(true)
          if (typeof callBack === 'function') callBack()
        }
      }
    },
    [ref, callBack, enabled]
  )

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [handleClickOutside])

  return {
    ref,
    isClickedOutside,
    setIsClickedOutside,
    setClickOutsideEnabled: setEnabled,
  }
}
