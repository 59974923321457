import { CloseButton } from './CloseButton'
import './MainGroupCompetenceItem.scss'
import { MetaData } from './MetaData'
import { SVGButton } from './SVGButton'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default observer(function MainGroupCompetenceItem(props) {
  const { t } = useTranslation(['framework'], { useSuspense: true })
  const { data, onSelect, selected, selectedItemIndex, index, style, color } =
    props
  const [notSelectedClass, setNotSelectedClass] = useState('')
  const [examplePageIndex, setExamplePageIndex] = useState(0)
  const [slideDirection, setSlideDirection] = useState('')

  const SLIDE_DIR = {
    LEFT: 'left',
    RIGHT: 'right',
  }

  const slideList = useRef(
    [].concat(data.description ?? []).concat(data.example ?? [])
  )
  const nrOfDescriptionSlides = useRef([].concat(data.description ?? []).length)
  const nrOfSlides = useRef(slideList.current.length)

  const onClick = () => {
    onSelect(index)
  }

  const onClose = () => {
    onSelect()
  }

  const onNextExample = () => {
    let val = Math.min(examplePageIndex + 1, nrOfSlides.current - 1)
    console.log('NEXT', slideList.current[val])
    setExamplePageIndex(val)
    setSlideDirection(SLIDE_DIR.LEFT)
  }
  const onPrevExample = () => {
    let val = Math.max(0, examplePageIndex - 1)
    console.log('PREV', val)
    setExamplePageIndex(val)
    setSlideDirection(SLIDE_DIR.RIGHT)
  }

  const isDescription = useCallback(() => {
    return examplePageIndex < nrOfDescriptionSlides.current
  }, [examplePageIndex])

  useEffect(() => {
    if (selectedItemIndex !== null) {
      if (selectedItemIndex !== index) {
        setNotSelectedClass('hidden')
      } else {
        //item is selected
        setNotSelectedClass('')
      }
    } else {
      //nothing selected
      setNotSelectedClass('')
    }

    return () => {}
  }, [selectedItemIndex, setNotSelectedClass, index])

  useEffect(() => {
    setExamplePageIndex(0)
    return () => {
      setExamplePageIndex(0)
    }
  }, [setExamplePageIndex])

  return (
    <div
      role={!selected ? 'button' : ''}
      onClick={!selected ? onClick : null}
      className={
        'group-item-competence-container ' +
        color +
        ' ' +
        (selected ? 'selected' : notSelectedClass)
      }
      style={style}
    >
      {!selected && (
        <div className={'group-item-competence-title'}>{data.title}</div>
      )}

      {selected && (
        <React.Fragment>
          <div className='group-item-competence-example'>
            <div className='group-item-competence-example-header'>
              <div className='group-item-competence-title'>{data.title}</div>{' '}
              <CloseButton onClose={onClose} />
            </div>

            <div className='group-item-competence-example-body'>
              {/* example node is array (many examples) show first only (temp) */}
              {slideList.current &&
                Array.isArray(slideList.current) &&
                slideList.current.length > 0 && (
                  <div
                    className={
                      'group-item-competence-example-body-inner ' +
                      slideDirection
                    }
                    key={examplePageIndex}
                  >
                    <div className='group-item-label mt-2'>
                      {isDescription()
                        ? t('labels.description')
                        : t('labels.example')}
                    </div>
                    <MetaData
                      data={slideList.current[examplePageIndex]}
                      isDescription={isDescription()}
                      t={t}
                      color={color}
                    />
                    <SlideContent
                      data={slideList.current[examplePageIndex]}
                      isDescription={isDescription()}
                      t={t}
                    />
                  </div>
                )}
              {slideList.current.length === 0 && (
                <span>{t('message.noData')}</span>
              )}
              {slideList.current &&
                Array.isArray(slideList.current) &&
                slideList.current.length > 0 && (
                  <div className='group-item-competence-example-body-nav'>
                    <div className='button-container'>
                      <SVGButton
                        buttonClass='arrow-button left'
                        onClick={onPrevExample}
                        disabled={examplePageIndex <= 0}
                      />
                      <SVGButton
                        buttonClass='arrow-button right'
                        onClick={onNextExample}
                        disabled={
                          examplePageIndex === slideList.current.length - 1
                        }
                      />
                    </div>
                    <div className='dot-indicator'>
                      {slideList.current.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={
                              'dot ' +
                              (index === examplePageIndex ? 'selected' : '')
                            }
                          ></div>
                        )
                      })}
                    </div>
                  </div>
                )}
            </div>
          </div>

          {/* <div className='group-item-competence-example-footer'></div> */}
        </React.Fragment>
      )}
    </div>
  )
})

const SlideContent = ({ data, isDescription, t }) => {
  console.log(data)
  let returnValue = null
  if (data) {
    if ('body' in data) {
      returnValue = data.body
    } else if (isDescription) {
      returnValue = t('message.noDescription')
    } else {
      returnValue = t('message.noExample')
    }
  }

  return (
    <div
      className='slide-content'
      dangerouslySetInnerHTML={{
        __html: returnValue,
      }}
    ></div>
  )
}
