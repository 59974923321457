import { AddCompetenceButton } from '../components/common/AddCompetenceButton'
import CompetenceList from '../components/common/CompetenceList'
import { Page } from '../containers/Page'
import { useStores } from '../stores'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Walkthrough from '../components/walkthrough/Walkthrough'
import BoardOptions from '../components/common/BoardOptions'
import LocalizedImage from '../components/common/LocalizedImage'

import dcpLogo from './../../assets/Logo.png'
import './Board.scss'

export default observer(function Board() {
  const { t, ready } = useTranslation(['common', 'board'], {
    useSuspense: true,
  })
  const { commonStore, boardStore } = useStores()
  const { boardid } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (ready && boardStore.currentBoardId !== boardid) {
      boardStore.getBoardById(boardid).then((success) => {
        if (process.env.REACT_APP_DEBUG) console.log('board loaded?', success)
        if (success === false) navigate('/')
      })
    }
  }, [boardStore, boardid, navigate, ready])

  useEffect(() => {
    commonStore.setSideBarItems(true)
    commonStore.setSideBarOpen(false)
    commonStore.setSideMenuShow(true)
  }, [commonStore])

  useEffect(() => {
    document.title =
      t('common:titleAbbreviation') + '-' + boardStore.currentBoardTitle
    return () => {
      document.title = t('common:title')
    }
  }, [commonStore.lang, boardStore.currentBoardTitle, t])

  return (
    !commonStore.loadingStandardCompetences && (
      <Page
        bgClassName='background-blob'
        splitOffset={200}
        logo={
          <LocalizedImage
            lang={commonStore.lang}
            fallback={dcpLogo}
            src='Logo.png'
            className='dcp-logo-background'
            alt={t('common:title')}
          />
        }
      >
        <Walkthrough />
        <div className='board-container col'>
          <div className='row justify-content-center'>
            <div className='col-11 '>
              <div className='board-header'>
                <div className='board-header-body'>
                  <h2 className='board-header-title'>
                    {boardStore.currentBoardTitle}
                  </h2>
                  {commonStore.sectors.length > 0 &&
                    commonStore.educationLevels.length > 0 && (
                      <p className='board-header-sub-title'>
                        {commonStore.sectors[boardStore.currentSector]?.title}
                        &nbsp;|&nbsp;
                        <span className='education'>
                          {
                            commonStore.educationLevels[
                              boardStore.currentEducationLevel
                            ]?.title
                          }
                        </span>
                      </p>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className='row justify-content-center mb-4'>
            <div className='col-11 '>
              <BoardOptions />
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-11 board-section-title'>
              {t('board:labels.myCompetences')}
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-2 '>
              <AddCompetenceButton />
            </div>
            <div className='col-8 '>
              <div className='board-body'>
                <CompetenceList />
              </div>
            </div>
            <div className='col-2'></div>
          </div>
        </div>
      </Page>
    )
  )
})
