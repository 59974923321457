export const mainStyles = {
  tooltipTitle: {
    fontSize: '0.875rem',
    fontWeight: '600',
  },
  tooltipContent: {
    fontSize: '0.875rem',
  },
  buttonNext: {
    borderRadius: '0px',
    borderBottomRightRadius: '10px',
    marginRight: '-14px',
    width: '50%',
  },
  buttonSkip: {
    borderRadius: '0px',
    borderBottomLeftRadius: '10px',
    marginLeft: '-14px',
    minWidth: '70%',
  },
  tooltip: {
    paddingBottom: '1px',
    borderRadius: '10px',
  },
}

export const WALKTHROUGH_CONTENT_TYPE = {
  BOARD: 'board',
  FRAMEWORK: 'framework',
  MENU: 'menu',
  REFLECT: 'reflect',
}
