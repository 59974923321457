import editIcon from '../../../assets/icon-edit.svg'
import trashIcon from '../../../assets/icon-trash.svg'
import { useStores } from '../../stores'
import './CompetenceExampleView.scss'
import { SVGButton } from './SVGButton'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default observer(function CompetenceExampleView(props) {
  const { t } = useTranslation(['common', 'board'], { useSuspense: true })
  const { boardStore, commonStore } = useStores()
  const exampleRef = useRef()
  const {
    myCompetenceId,
    groupId,
    exampleId,
    position,
    onDeleteExample,
    onEditExample,
    onHideSelf,
    isValid,
  } = props
  const [inRightViewPort, setInRightViewPort] = useState(false)

  useEffect(() => {
    //self hide if example is removed by user
    if (!isValid) {
      onHideSelf()
    }
  }, [isValid, onHideSelf])

  useEffect(() => {
    if (commonStore.viewPortSplitBoundary > 0) {
      var bounding = exampleRef.current.getBoundingClientRect()
      setInRightViewPort(bounding.right > commonStore.viewPortSplitBoundary)
    }
  }, [commonStore.viewPortSplitBoundary])

  return (
    commonStore.viewPortSplitBoundary > 0 && (
      <React.Fragment>
        <div
          ref={exampleRef}
          className={'competence-example-container '}
          style={{ top: position.y + 'px', left: position.x + 'px' }}
        >
          <div
            className={
              'competence-example' +
              (inRightViewPort ? ' right-top' : ' center')
            }
          >
            <div className='competence-example-inner'>
              <div className='competence-example-title'>
                {t('board:examplePopover.title')}
              </div>
              <div className='competence-example-body'>
                {boardStore.getCompetenceExampleById(
                  myCompetenceId,
                  groupId,
                  exampleId
                )}
              </div>
              <div className='competence-example-footer'>
                <SVGButton
                  icon={editIcon}
                  tt={t('board:tooltips.editExample')}
                  onClick={onEditExample}
                  buttonClass='btn-dcp-exampleview-button'
                  label={t('common:buttonLabels.edit')}
                />
                <SVGButton
                  icon={trashIcon}
                  tt={t('board:tooltips.deleteExample')}
                  onClick={onDeleteExample}
                  buttonClass='btn-dcp-exampleview-button'
                  label={t('common:buttonLabels.delete')}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  )
})
