import close from './../../../assets/icon-close.svg'

import './CloseButton.scss'

export const CloseButton = (props) => {
  var { onClose = () => {}, tt = '' } = props

  return (
    <div role='button' title={tt} onClick={onClose} className='close-button'>
      <img src={close} alt='close' />
    </div>
  )
}
