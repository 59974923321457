import { useStores } from '../../stores'
import useClickOutside from '../../utils/useClickOutside'
import './LangSwitch.scss'
import './SideMenu.scss'
import { t } from 'i18next'
import { observer } from 'mobx-react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default observer(function LangSwitch(props) {
  const { commonStore } = useStores()
  const { t } = useTranslation(['common'], { useSuspense: true })

  const { ref, setIsClickedOutside, setClickOutsideEnabled } = useClickOutside(
    () => {
      commonStore.setLangSelectOpened(false)
    },
    false
  )

  useEffect(() => {
    if (!commonStore.langSelectOpened) {
      setIsClickedOutside(false)
    }
  }, [commonStore.langSelectOpened, setIsClickedOutside])

  useEffect(() => {
    if (commonStore.langSelectOpened) {
      setClickOutsideEnabled(true)
    } else {
      setClickOutsideEnabled(false)
    }
  }, [commonStore.langSelectOpened, setClickOutsideEnabled])

  const toggleMenu = () => {
    commonStore.setLangSelectOpened(!commonStore.langSelectOpened)
  }

  return (
    commonStore.lang &&
    commonStore.localeData.length > 0 && (
      <div ref={ref} className='locale-switch-container'>
        <div className='locale-current-selection-container'>
          <div
            role='button'
            onClick={toggleMenu}
            className={
              'locale-current-selection-flag-button' +
              (commonStore.langSelectOpened ? ' active' : '')
            }
          >
            <div className='locale-switch-container-label'>{t('language')}</div>
            <Flag data={commonStore.getCurrentLocaleData()} />
          </div>
          {commonStore.langSelectOpened && (
            <div className='locale-current-selection-label'>
              {commonStore.getCurrentLocaleData().nativeName}
            </div>
          )}
        </div>

        {commonStore.langSelectOpened && (
          <ul className='locale-items'>
            {commonStore.localeData.map((item, index) => {
              return (
                <li
                  className='locale-item'
                  role='button'
                  key={index}
                  onClick={() => {
                    toggleMenu()
                    commonStore.setLang(item.code)
                  }}
                  style={{ animationDelay: index * 0.1 + 's' }}
                >
                  <Flag data={item} />
                  <LocaleLabel label={item.nativeName} />
                </li>
              )
            })}
          </ul>
        )}
      </div>
    )
  )
})

const Flag = ({ data }) => {
  const FLAG_ALIGNMENT = {
    LEFT: 'left',
    RIGHT: 'right',
    CENTER: 'center',
  }

  return (
    <div
      className='flag-container'
      style={{
        justifyContent:
          data.flagAlignment === FLAG_ALIGNMENT.LEFT
            ? 'flex-start'
            : data.flagAlignment === FLAG_ALIGNMENT.RIGHT
            ? 'flex-end'
            : 'center',
      }}
    >
      <img src={data.flagURI} className='flag' alt={data.nativeName}></img>
    </div>
  )
}

const LocaleLabel = ({ label }) => {
  const [animateLabel, setAnimateLabel] = useState(false)
  const container = useRef(null)
  const innerContainer = useRef(null)

  useEffect(() => {
    setAnimateLabel(
      container.current?.clientWidth < innerContainer.current?.clientWidth
    )
  }, [setAnimateLabel, container, innerContainer])

  return (
    <div
      ref={container}
      className={'locale-item-label' + (animateLabel ? ' animate-label' : '')}
    >
      <div ref={innerContainer} className='locale-item-label-inner'>
        {label}
      </div>
    </div>
  )
}
