import 'bootstrap/dist/css/bootstrap.css'
// Put any other imports below so that CSS from your
// components takes precedence over default styles.

import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles/index.css'

import Routing from './app/components/route/Routing'
import { StoreProvider, stores } from './app/stores'

import reportWebVitals from './reportWebVitals'
import './styles/_main.scss'

import './js/i18n'
import Spinner from './app/components/common/Spinner'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <React.Suspense fallback={<Spinner />}>
      <StoreProvider>
        <Routing />
      </StoreProvider>
    </React.Suspense>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
