import { useStores } from '../../stores'
import { POPUP_TYPE } from '../../utils/Enums'
import link from './../../../assets/icon-link.svg'
import trash from './../../../assets/icon-trash.svg'
import { CloseButton } from './CloseButton'
import './CompetenceCard.scss'
import LinkGroupIndicatorContainer from './LinkGroupIndicatorContainer'
import { OptionsButton } from './OptionsButton'
import { OptionsMenu } from './OptionsMenu'
import PopoverGroupCompetence from './PopoverGroupCompetence'
import PopoverLinkGroup from './PopoverLinkGroup'
import { SVGButton } from './SVGButton'
import TinyPopup from './TinyPopup'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { WALKTHROUGH_CONTENT_TYPE } from '../walkthrough/joyride'

export const OPTION_TYPE = {
  DELETE: 10,
  EDIT: 20,
  DUPLICATE: 30,
}

export default observer(function CompetenceCard(props) {
  const { t } = useTranslation(['common', 'board'], {
    useSuspense: true,
  })
  const { boardStore, popupMessageStore, walkthroughStore } = useStores()
  const { data, onClick, onTop } = props

  const [options, setOptions] = useState(false)
  const [
    showDeleteMyCompetenceWarningPopOver,
    setShowDeleteMyCompetenceWarningPopOver,
  ] = useState(false)
  const [showDeleteExampleWarningPopOver, setShowDeleteExampleWarningPopOver] =
    useState(false)
  const [showLinkGroupPopOver, setShowLinkGroupPopOver] = useState(false)
  const [showGroupCompentencePopOver, setShowGroupCompentencePopOver] =
    useState(false)
  const [groupCompentencePopOverPosition, setGroupCompentencePopOverPosition] =
    useState(null)
  const [deleteExampleData, setDeleteExampleData] = useState({})
  const [selectedGroupId, setSelectedGroupId] = useState('')

  const toggleOptions = useCallback(() => {
    setShowGroupCompentencePopOver(false)
    setShowLinkGroupPopOver(false)
    setOptions(!options)
  }, [options])

  const onOption = (type) => {
    switch (type) {
      case OPTION_TYPE.DELETE:
        setShowDeleteMyCompetenceWarningPopOver(true)
        break
      case OPTION_TYPE.EDIT:
        popupMessageStore.showPopupMessage({
          type: POPUP_TYPE.EDIT_COMPETENCE,
          myCompetenceData: data,
        })
        break
      default:
    }
    setOptions(false)
  }
  const onDeleteCardConfirm = useCallback(() => {
    boardStore
      .deleteMyCompetence(data.id)
      .then(setShowDeleteMyCompetenceWarningPopOver(false))
  }, [data, boardStore])

  const onDeleteCardConfirmPopoverClose = useCallback(() => {
    setShowDeleteMyCompetenceWarningPopOver(false)
  }, [])

  const onDeleteExampleConfirmPopoverClose = useCallback(() => {
    setShowDeleteExampleWarningPopOver(false)
  }, [])

  const onDeleteExampleConfirm = useCallback(() => {
    boardStore
      .deleteCompetenceExample(
        deleteExampleData.myCompetenceId,
        deleteExampleData.groupId,
        deleteExampleData.subCompetenceId
      )
      .then(setShowDeleteExampleWarningPopOver(false))
  }, [boardStore, deleteExampleData])

  const onDeleteExample = useCallback((exampleData) => {
    setDeleteExampleData(exampleData)
    setShowDeleteExampleWarningPopOver(true)
  }, [])

  const toggleLinkGroupPopover = useCallback(() => {
    setOptions(false)
    setShowGroupCompentencePopOver(false)
    setShowLinkGroupPopOver(!showLinkGroupPopOver)
    walkthroughStore.nextStep()
  }, [showLinkGroupPopOver, walkthroughStore])

  const toggleGroupCompetencePopover = useCallback(
    (groupId, position) => {
      setOptions(false)
      setShowLinkGroupPopOver(false)
      setSelectedGroupId(showGroupCompentencePopOver ? '' : groupId)
      setGroupCompentencePopOverPosition(position)
      setShowGroupCompentencePopOver(!showGroupCompentencePopOver)
      walkthroughStore.nextStep()
    },
    [showGroupCompentencePopOver, walkthroughStore]
  )

  useEffect(() => {
    if (!onTop) {
      setOptions(false)
      setShowLinkGroupPopOver(false)
    }
  }, [onTop])

  const closePopovers = useCallback(() => {
    setShowDeleteMyCompetenceWarningPopOver(false)
    setShowDeleteExampleWarningPopOver(false)

    if (
      !walkthroughStore.walkthroughIsRunning ||
      (walkthroughStore.walkthroughIsRunning &&
        walkthroughStore.currentType === WALKTHROUGH_CONTENT_TYPE.FRAMEWORK)
    ) {
      // if walkthrough is running then don't delete these..
      // it otherwise will cause a TypeError (TypeError: Cannot read property 'isSameNode' of undefined)
      // and the ErrorBoundary will kick in.
      setShowGroupCompentencePopOver(false)
      setShowLinkGroupPopOver(false)
    }
  }, [walkthroughStore.currentType, walkthroughStore.walkthroughIsRunning])

  useEffect(() => {
    window.addEventListener('resize', closePopovers)
    return () => {
      window.removeEventListener('resize', closePopovers)
    }
  }, [closePopovers])

  return (
    <React.Fragment>
      <div
        className={'competence-card ' + (onTop ? 'on-top' : '')}
        data-id={data.id}
        onClick={onClick}
      >
        <OptionsMenu show={options} onOption={onOption} />
        <div className='competence-card-inner'>
          <div className='competence-card-background'></div>
          <div className='competence-card-header'>
            <div className='index-container'>{data.cardNr}</div>
            {options && (
              <CloseButton
                tt={t('board:tooltips.optionsclose')}
                onClose={toggleOptions}
              />
            )}
            {!options && (
              <OptionsButton
                tt={t('board:tooltips.optionsopen')}
                onClick={toggleOptions}
              />
            )}
          </div>
          <div className='competence-card-body'>{data.body}</div>

          <div className='competence-card-footer-labels'>
            <div className='link'>{t('board:labels.card.linkGroups')}</div>
            <div className='indicator'>
              {t('board:labels.card.linkCompetences')}
            </div>
          </div>
          <div className='competence-card-footer'>
            <SVGButton
              icon={boardStore.getLinkedGroupNrs(data.id) <= 0 ? link : ''}
              tt={t('board:tooltips.linkGroups')}
              buttonClass='btn-dcp-icon-circle linkgroup-button'
              active={showLinkGroupPopOver}
              onClick={toggleLinkGroupPopover}
              label={boardStore.getLinkedGroupNrs(data.id)}
            />
            <LinkGroupIndicatorContainer
              onIndicatorClick={toggleGroupCompetencePopover}
              myCompetenceId={data.id}
            />
          </div>
        </div>
        {showGroupCompentencePopOver && (
          <PopoverGroupCompetence
            myCompetenceId={data.id}
            groupId={selectedGroupId}
            position={groupCompentencePopOverPosition}
            onClose={toggleGroupCompetencePopover}
            onDeleteExample={onDeleteExample}
          />
        )}
        {showLinkGroupPopOver && (
          <PopoverLinkGroup
            onClose={toggleLinkGroupPopover}
            competenceId={data.id}
          />
        )}
      </div>
      {(showLinkGroupPopOver || showGroupCompentencePopOver) && (
        <div
          className='competence-card-background-cover'
          onClick={
            showLinkGroupPopOver
              ? toggleLinkGroupPopover
              : showGroupCompentencePopOver
              ? toggleGroupCompetencePopover
              : () => {}
          }
        ></div>
      )}
      {showDeleteMyCompetenceWarningPopOver && (
        <TinyPopup
          onClose={onDeleteCardConfirmPopoverClose}
          onConfirm={onDeleteCardConfirm}
          confirmLabel={t('common:buttonLabels.delete')}
          cancelLabel={t('common:buttonLabels.cancel')}
          popupClass='popup-delete-competence'
        >
          <img src={trash} alt='trash icon'></img>
          <p
            dangerouslySetInnerHTML={{
              __html: t('board:message.tinypopups.card.deleteSure', {
                nr: data.cardNr,
              }),
            }}
          ></p>
          <small>
            {t('board:message.tinypopups.card.deleteSureExplain', {
              nr: data.cardNr,
            })}
          </small>
        </TinyPopup>
      )}
      {showDeleteExampleWarningPopOver && (
        <TinyPopup
          onClose={onDeleteExampleConfirmPopoverClose}
          onConfirm={onDeleteExampleConfirm}
          confirmLabel={t('common:buttonLabels.delete')}
          cancelLabel={t('common:buttonLabels.cancel')}
          popupClass='popup-delete-competence'
        >
          <img src={trash} alt='trash icon'></img>
          <p
            dangerouslySetInnerHTML={{
              __html: t('board:message.tinypopups.example.deleteSure'),
            }}
          ></p>
          <small>
            {t('board:message.tinypopups.example.deleteSureExplain')}
          </small>
        </TinyPopup>
      )}
    </React.Fragment>
  )
})
