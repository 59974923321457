import icon from '../../../assets/icon-add-white.svg'
import { useStores } from '../../stores'
import { POPUP_TYPE } from '../../utils/Enums'
import './AddCompetenceButton.scss'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export const AddCompetenceButton = (props) => {
  var { onClose = () => {} } = props

  const { t } = useTranslation(['board'], { useSuspense: true })
  const { popupMessageStore } = useStores()

  const addCompetence = useCallback(() => {
    popupMessageStore.showPopupMessage({
      type: POPUP_TYPE.NEW_COMPETENCE,
      description: 'new competence popup',
    })
  }, [popupMessageStore])

  //return <button onClick={onClose} className="btn btn-primary btn-sm btn-icon btn-icon-xl"><FontAwesomeIcon icon={faTimes}/></button>
  return (
    <div
      className='add-competence-button'
      role='button'
      onClick={addCompetence}
    >
      <div className='add-competence-background'></div>
      <img className='add-competence-icon' alt='add new board' src={icon} />
      <div
        className='add-competence-label'
        dangerouslySetInnerHTML={{
          __html: t('buttonLabels.addCompetence'),
        }}
      ></div>
    </div>
  )
}
