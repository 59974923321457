import { observer } from 'mobx-react'
import { useCallback, useState } from 'react'

import { useStores } from '../../stores'
import CompetenceCard from './CompetenceCard'

import './CompetenceList.scss'

export default observer(function CompetenceList() {
  const [lastClickedId, setLastCLickedId] = useState('')
  const { boardStore } = useStores()

  const onClick = useCallback(
    (event) => {
      setLastCLickedId(event.currentTarget.dataset.id)
    },
    [setLastCLickedId]
  )

  return boardStore.myCompetenceList
    .slice(0)
    .reverse()
    .map((item, index) => {
      return (
        <CompetenceCard
          data={item}
          onTop={lastClickedId === item.id}
          key={index}
          index={index}
          onClick={onClick}
        />
      )
    })
})
