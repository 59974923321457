import i18n from '../../js/i18n'
import { action, makeAutoObservable, observable } from 'mobx'

export class CommonStore {
  /** holds the current selected locale code */
  lang
  /**holds the list of available languages */
  localeData
  langSelectOpened

  loadingStandardCompetences

  standardCompetences
  sectors
  educationLevels

  messageList

  showSideBarRight
  showSideBarC2a
  sideBarRightOpened
  sideBarRightItems

  showSideMenu
  sideMenuOpened

  viewPortSplitBoundary

  constructor(webservice) {
    //this.commonStore = commonStore
    this.webservice = webservice
    this.initValues()
    makeAutoObservable(this)
  }

  initValues() {
    this.loadingMyCompetences = false
    this.standardCompetences = []
    this.sectors = []
    this.educationLevels = []
    this.localeData = []
    this.setLang(localStorage.getItem('lang') || 'nl-nl')
    this.langSelectOpened = false

    this.showSideBarRight = true
    this.showSideBarC2a = false
    this.sideBarRightOpened = false
    this.sideBarRightItems = true
    this.showSideMenu = false
    this.sideMenuOpened = false
    this.viewPortSplitBoundary = window.innerWidth * 0.5
    this.messageList = []
  }

  getShortLangCode() {
    let returnValue
    switch (this.lang) {
      case 'nl-nl':
        returnValue = 'nl'
        break
      case 'en-gb':
      case 'en-uk':
      case 'en-us':
        returnValue = 'en'
        break
      default:
        returnValue = 'nl'
    }
    return returnValue
  }

  setShowSideBarC2a(val) {
    this.showSideBarC2a = val
  }

  setViewPortSplitBoundary(val) {
    this.viewPortSplitBoundary = val ?? window.innerWidth * 0.5
  }

  setSideMenuShow(show) {
    this.showSideMenu = show ?? true
  }

  setSideMenuOpen(open) {
    this.sideMenuOpened = open ?? true
  }

  setSideBarShow(show) {
    this.showSideBarRight = show ?? true
  }

  setSideBarOpen(open) {
    this.sideBarRightOpened = open ?? true
  }

  setSideBarItems(show) {
    this.sideBarRightItems = show ?? true
  }

  setLang(value) {
    this.lang = value
    i18n.changeLanguage(value)
    localStorage.setItem('lang', value)
  }
  setLangSelectOpened(open) {
    this.langSelectOpened = open ?? true
  }
  setLocaleData(data) {
    this.localeData = data
  }
  getCurrentLocaleData() {
    return this.localeData.filter((item) => {
      return item.code === this.lang
    })[0]
  }
  setStandardCompetences(data) {
    this.standardCompetences = data
  }
  setSectors(data) {
    this.sectors = data
  }
  setEducationlevels(data) {
    this.educationLevels = data
  }

  getSectorById(id) {
    return this.sectors.filter((item) => {
      return item.id === id
    })[0]
  }
  getEducationlevelById(id) {
    return this.educationLevels.filter((item) => {
      return item.id === id
    })[0]
  }

  getStaticGroupDataByGroupId(groupId) {
    if (this.standardCompetences.length > 0) {
      return this.standardCompetences.filter((item) => {
        return item.id === groupId
      })[0]
    } else {
      return []
    }
  }

  getLocalizedAsset(asset) {
    return process.env.PUBLIC_URL + '/assets/' + this.lang + '/' + asset
  }

  // getStandardCompetences() {
  //   this.loadingStandardCompetences = true
  //   var p = new Promise((resolve, reject) => {
  //     if (this.standardCompetences.length == 0) {
  //       this.webservice
  //         .getStandardCompetences(this.lang)
  //         .then(
  //           action('getStandardCompetencesResponse', (response) => {
  //             console.log('in store', response)
  //             this.standardCompetences = response.groups
  //             resolve(response.success)
  //           })
  //         )
  //         .catch(this.requestError)
  //     } else {
  //       resolve(true)
  //     }
  //   })
  //   return p
  // }

  showAlert(msg, type = 'c2a', persist = true) {
    this.messageList.push({ msg, type, persist })
  }

  clearMessages() {
    this.messageList = []
  }

  clearMessage(index) {
    this.messageList.splice(index, 1)
  }
}
