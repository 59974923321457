import { AxiosError } from 'axios'

import { action, observable, computed, makeAutoObservable } from 'mobx'

import { POPUP_TYPE } from '../utils/Enums'

export class PopupMessageStore {
  displayingPopupMessage

  nextMessage

  popupType

  counter

  constructor(commonStore, webservice) {
    this.commonStore = commonStore
    this.webservice = webservice

    this.initValues()
    makeAutoObservable(this)
  }

  initValues() {
    this.counter = 0
    this.displayingPopupMessage = false
    this.nextMessage = undefined
    this.popupType = null
    this.queue = []
    //system messages
    this.messageList = this.commonStore.messageList
  }

  showPopupMessage(data) {
    console.log(data, this.displayingPopupMessage)
    if (!this.displayingPopupMessage) {
      if (data.hasOwnProperty('type')) {
        this.popupType = data.type
        this.nextMessage = data
        this.displayingPopupMessage = true
      }
    } else {
      //add message to waiting list
      this.queue.push(data)
    }
  }

  count() {
    this.counter += 1
    console.log(this.counter)
  }

  clearPopupMessage() {
    this.displayingPopupMessage = false

    //clear all popuptypes here.
    this.nextMessage = undefined
    this.popupType = null

    //any messages in queue?
    if (this.queue.length > 0) {
      this.showPopupMessage(this.queue.shift())
    }
  }
}
