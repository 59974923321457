import blackCheckmark from '../../../assets/icon-check-black.svg'
import { useStores } from '../../stores'
import { BigBottomButton } from './BigBottomButton'
import { CloseButton } from './CloseButton'
import LinkGroupItem from './LinkGroupItem'
import './PopoverLinkGroup.scss'
import { observer } from 'mobx-react'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export default observer(function PopoverLinkGroup(props) {
  const { t } = useTranslation(['common', 'board'], {
    useSuspense: true,
  })
  const { commonStore, boardStore, walkthroughStore } = useStores()
  const { onClose, competenceId, popupClass = '' } = props

  const onToggleGroup = useCallback(
    (groupId) => {
      boardStore.toggleMainGroup(competenceId, groupId)
      //walkthrough
      walkthroughStore.nextStep()
    },
    [boardStore, competenceId, walkthroughStore]
  )

  return (
    <div className='linkgroup-popover-container'>
      <div className={'linkgroup-popover ' + popupClass}>
        {/* <div className='linkgroup-popover-background'></div> */}
        <div className='linkgroup-popover-header'>
          <div className='linkgroup-popover-header-title'>
            {t('board:message.c2aLinkGroup')}
          </div>
        </div>
        <div className='linkgroup-popover-body'>
          {commonStore.standardCompetences.map((item, index) => {
            return (
              <LinkGroupItem
                key={index}
                data={item}
                selected={boardStore.getGroupLinkedStatus(
                  competenceId,
                  item.id
                )}
                onClick={onToggleGroup}
              />
            )
          })}
        </div>
        <div className='linkgroup-popover-footer'>
          <BigBottomButton
            onClick={onClose}
            svgIcon={blackCheckmark}
            buttonClass='big-confirm-button sm'
            label={t('common:buttonLabels.confirm')}
          />
        </div>
      </div>
    </div>
  )
})
