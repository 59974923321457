export function SVGButton({
  onClick = () => {},
  icon,
  tt = '',
  buttonClass = '',
  active = false,
  disabled = false,
  dataId = '',
  label = '',
  ...props
}) {
  return (
    <button
      onClick={onClick}
      title={tt}
      className={
        'btn-svg-icon ' +
        buttonClass +
        (active ? ' active' : '') +
        (disabled ? ' disabled' : '')
      }
      data-id={dataId}
      data-label={label}
      {...props}
    >
      <div className='background'></div>
      {icon && <img src={icon} alt={tt} />}
    </button>
  )
}
