import blackCheckmark from '../../../assets/icon-check-black.svg'
import { useStores } from '../../stores'
import './LinkIndicator.scss'

export const LinkIndicator = (props) => {
  const { boardStore } = useStores()
  var { onClick = () => {}, tt = '', data, linked, myCompetenceId } = props

  return (
    <div
      role='button'
      title={linked ? tt : ''}
      onClick={linked ? onClick : () => {}}
      data-id={data.id}
      className={
        'link-status-indicator-button ' + (linked ? data.color : 'disabled')
      }
    >
      <div className='competence-indicator-container'>
        {linked &&
          data.competences.map((item, index) => {
            let selected = boardStore.getCompetenceLinkedStatus(
              myCompetenceId,
              data.id,
              item.id
            )
            let hasExample = boardStore.getCompetenceHasExampleStatus(
              myCompetenceId,
              data.id,
              item.id
            )
            return (
              <div
                key={index}
                data-id={item.id}
                className={
                  'competence-indicator i-' +
                  index +
                  (selected ? ' selected ' + data.color : ' ')
                }
              >
                {selected && hasExample && (
                  <img src={blackCheckmark} alt='check' />
                )}
              </div>
            )
          })}
      </div>
      {linked && (
        <div className='counter'>
          {boardStore.getLinkedCompetenceNrs(myCompetenceId, data.id)}
        </div>
      )}
    </div>
  )
}
