import React, { useCallback, useEffect, useRef, useState } from 'react'
/**
 * Returns an img from locale folder inside /public/assets/ folder
 * @param {fallback} fallback image (must be complete uri path)
 * @param {src} filename filename of asset including extension..
 * all localized files must have the same name in their respective locale folders.
 * @param {lang} language language code (== locale foldername inside PUBLIC_URL/assets/ folder)
 * @param {alt} alt alt value of image
 * @returns
 */
export default function LocalizedImage({
  fallback,
  src,
  alt = '',
  lang,
  ...props
}) {
  const [usesFallback, setUsesFallback] = useState(false)
  const imageRef = useRef()

  const onError = useCallback(() => {
    setUsesFallback(true) //prevents endless looping if fallback is not found.
    imageRef.current.src = fallback
  }, [fallback])

  useEffect(() => {
    setUsesFallback(false)
    imageRef.current.src =
      process.env.PUBLIC_URL + '/assets/' + lang + '/' + src
  }, [lang, src])

  return (
    <img
      ref={imageRef}
      alt={alt}
      onError={usesFallback ? null : onError}
      {...props}
    />
  )
}
