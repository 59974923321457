import whiteCheckmark from '../../../assets/icon-check-white.svg'
import './LinkGroupItem.scss'
import { observer } from 'mobx-react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default observer(function LinkGroupItem(props) {
  const { data, selected, onClick, style } = props
  const { t } = useTranslation(['board'], { useSuspense: true })
  const [clicked, setClicked] = useState(false)

  const onToggle = useCallback(() => {
    onClick(data.id)
    setClicked(true)
  }, [data, onClick])

  return (
    <div
      role='button'
      onClick={onToggle}
      className={
        'link-group-item-container ' +
        data.color +
        (selected ? ' selected' : '') +
        (clicked ? ' clicked' : '')
      }
      style={style}
    >
      <div className='link-group-item-inner'>
        <div className='link-group-item-header'>
          <div className='link-group-item-title'>{data.title}</div>
        </div>
        <div className='link-group-item-body'>
          <div className='link-group-item-checkbox'>
            <div className='inner-checkbox'>
              {selected && <img src={whiteCheckmark} alt='checkmark' />}
            </div>
          </div>
        </div>
        <div className='link-group-item-footer'>
          <div className={'link-group-item-label'}>
            {selected ? t('buttonLabels.linked') : t('buttonLabels.notlinked')}
          </div>
        </div>
      </div>
    </div>
  )
})
