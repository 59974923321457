import { makeAutoObservable } from 'mobx'

import { WALKTHROUGH_CONTENT_TYPE } from '../components/walkthrough/joyride'

export class WalkthroughStore {
  stepIndex
  visited
  skipped
  /*true when joyride is actually running (run property on JoyRide component)*/
  run
  /**
   *   True when walkthrough is running even if run prop is set to false..
   *   this prop is used to resume joyride when needed after a user action
   * */
  walkthroughIsRunning
  currentType
  steps
  /** Holds per type a set of steps.. type is used as property */
  stepLibrary

  constructor(commonStore) {
    this.commonStore = commonStore
    this.initValues()
    makeAutoObservable(this)
  }

  initValues() {
    this.stepIndex = 0
    this.visited = localStorage.getItem('wt_visited')?.split('|') ?? []
    this.skipped = localStorage.getItem('wt_skipped')?.split('|') ?? []
    this.run = false
    this.walkthroughIsRunning = false
    this.currentType = WALKTHROUGH_CONTENT_TYPE.BOARD
    this.steps = [
      {
        target: 'body',
        title: 'Warning',
        content: 'No steps defined in walkthrough of type: ' + this.currentType,
      },
    ]
    this.stepLibrary = {}
  }

  setCurrentType(type) {
    this.currentType = type ?? WALKTHROUGH_CONTENT_TYPE.BOARD
  }

  setStepIndex(val) {
    this.stepIndex = val
  }

  setSteps(val) {
    this.steps = val
  }

  setVisited(type) {
    if (!this.getVisited(type)) {
      this.visited.push(type)
    }
    localStorage.setItem('wt_visited', this.visited.join('|'))
  }

  getVisited(type) {
    return this.visited.includes(type)
  }

  clearVisited() {
    this.visited = []
    localStorage.setItem('wt_visited', this.visited.join('|'))
  }

  // setSkipped(type) {
  //   if (!this.getSkipped(type)) {
  //     this.skipped.push(type)
  //   }
  //   localStorage.setItem('wt_skipped', this.skipped.join('|'))
  // }

  // getSkipped(type) {
  //   return this.skipped.includes(type)
  // }

  setRunWalkthrough(val) {
    this.run = val ?? true
  }

  setWalkthroughIsRunning(val) {
    this.walkthroughIsRunning = val ?? true
  }

  addStepsToLibrary(type, steps) {
    this.stepLibrary[type] = steps
  }

  nextStep() {
    if (this.walkthroughIsRunning) {
      this.setStepIndex(this.stepIndex + 1)
    }
  }

  previousStep() {
    if (this.walkthroughIsRunning) {
      this.setStepIndex(this.stepIndex - 1)
    }
  }

  start(type) {
    this.setCurrentType(type)
    this.setSteps(this.stepLibrary[this.currentType])
    this.setStepIndex(0)
    this.setWalkthroughIsRunning(true)
    this.setRunWalkthrough(true)
    if (this.currentType === WALKTHROUGH_CONTENT_TYPE.BOARD) {
      this.commonStore.setSideBarOpen(false)
    }
  }

  pause() {
    if (this.walkthroughIsRunning) {
      this.setRunWalkthrough(false)
    }
  }

  resume() {
    if (this.walkthroughIsRunning) {
      this.setRunWalkthrough(true)
    }
  }
}
