import { uniqueId } from '../utils/uniqueId'
import { action, makeAutoObservable, computed, toJS } from 'mobx'

export class BoardStore {
  currentBoardId
  newBoardId
  currentBoardTitle
  currentEducationLevel
  currentSector
  currentBoardData
  lastCompetenceIndex
  myCompetenceList

  constructor(commonStore, webservice, popupMessageStore) {
    //console.log("challengestore")
    this.commonStore = commonStore
    this.webservice = webservice
    this.popupMessageStore = popupMessageStore
    this.initValues()
    makeAutoObservable(this)
  }

  /**
    Sets (and resets) the initial values. Usefull when a reset of data is needed due to things like token corruption or localstorage issues.
    */

  initValues() {
    this.currentBoardId = ''
    this.newBoardId = ''
    this.currentBoardTitle = ''
    this.currentEducationLevel = ''
    this.currentSector = ''
    this.currentBoardData = {}
    this.myCompetenceList = []
    this.lastCompetenceIndex = 0
    this.messageList = this.commonStore.messageList
  }

  getCurrentBoardDataObject() {
    return { id: this.currentBoardId, data: toJS(this.currentBoardData) }
  }

  parseBoardDataResponse(response) {
    this.currentBoardId = response.board?.id
    this.currentBoardTitle = response.board?.data?.title
    this.currentEducationLevel = response.board?.data?.educationLevel
    this.currentSector = response.board?.data?.sector
    this.currentBoardData = response.board?.data
    this.lastCompetenceIndex =
      this.currentBoardData.lastCompetenceIndex !== undefined
        ? this.currentBoardData.lastCompetenceIndex ?? 0
        : 0
    this.myCompetenceList = this.currentBoardData.competences ?? []
  }

  setCurrentBoardTitle(val) {
    this.currentBoardTitle = val
  }
  setEducationLevel(val) {
    this.currentEducationLevel = val
  }
  setSector(val) {
    this.currentSector = val
  }

  getPdfURL() {
    return (
      window.location.origin +
      window.location.pathname +
      '?' +
      'cmd=1' +
      '&id=' +
      this.currentBoardId +
      '&lang=' +
      this.commonStore.getShortLangCode()
    )
  }

  /**
   *
   * @param {*} title
   * @param {*} sector
   * @param {*} educationLevel
   * @returns
   */
  createNewBoard(title, sector, educationLevel) {
    this.newBoardId = ''
    var p = new Promise((resolve, reject) => {
      this.webservice
        .createNewBoard({ title, sector, educationLevel })
        .then(
          action('createNewBoardResponse', (response) => {
            //do something with repsonse data
            this.newBoardId = response.board?.id
            resolve(response.success)
          })
        )
        .catch(this.requestError)
    })
    return p
  }

  /**
   * returns the board data based on the url param
   */
  getBoardById(id) {
    //console.log('fetch board data', id)
    var p = new Promise((resolve, reject) => {
      this.webservice
        .getBoardDataById(id)
        .then(
          action('getBoardByIdResponse', (response) => {
            if (process.env.REACT_APP_DEBUG)
              //console.log('board in store', response)
              //do something with repsonse data
              this.parseBoardDataResponse(response)
            resolve('board' in response)
          })
        )
        .catch((error) => {
          this.requestError(error)
          resolve(false)
        })
    })
    return p
  }

  updateBoard() {
    var p = new Promise((resolve, reject) => {
      let data = this.getCurrentBoardDataObject()
      // console.log(data)
      this.webservice
        .updateBoardData(data)
        .then(
          action('updateBoardResponse', (response) => {
            this.parseBoardDataResponse(response)
            resolve(response.success)
          })
        )
        .catch(this.requestError)
    })
    return p
  }

  /**
   * Adds a new competence object
   * @param {Curriculum competence text} text
   * @returns
   */
  createNewMyCompetence(text) {
    this.lastCompetenceIndex += 1
    let competence = new MyCompetence() //{ body: text, id: uniqueId(), groups: [] }
    competence.body = text
    competence.cardNr = this.lastCompetenceIndex

    competence.groups = this.commonStore.standardCompetences.map((item) => {
      //add contaoners for group linking data
      return new Group(item.id)
    })

    //check for competence node in data object
    if (!('competences' in this.currentBoardData)) {
      this.currentBoardData.competences = []
    }

    if (Array.isArray(this.currentBoardData.competences.slice())) {
      this.currentBoardData.competences.push(competence)
      this.currentBoardData.lastCompetenceIndex = this.lastCompetenceIndex
    }

    return this.updateBoard()
  }
  /**
   * Updates a competence object with the passed text. Returns a resolved promise when no changes are detected (fixes an 500 error).
   * @param {Curriculum competence text} text
   * @returns
   */
  updateMyCompetence(myCompetenceId, text) {
    let competence = this.getCurriculumCompetenceById(myCompetenceId)
    if (competence.body !== text) {
      competence.body = text
      return this.updateBoard()
    }
    return new Promise((resolve, reject) => {
      resolve(true)
    })
  }
  /**
   * Removes the competence
   * @param {*} myCompetenceId
   * @returns promise
   */
  deleteMyCompetence(myCompetenceId) {
    this.currentBoardData.competences =
      this.currentBoardData.competences.filter((item) => {
        return item.id !== myCompetenceId
      })
    return this.updateBoard()
  }

  /**
   * Toggles the linked status of a group at a curriculum competence
   * @param {*} myCompetenceId
   * @param {*} groupId
   * @returns promise
   */
  toggleMainGroup(myCompetenceId, groupId) {
    let myCompetence = this.getCurriculumCompetenceById(myCompetenceId)

    if (myCompetence) {
      //fixes an empty group list
      if (myCompetence.groups.length === 0) {
        myCompetence.groups = this.commonStore.standardCompetences.map(
          (item) => {
            return new Group(item.id)
          }
        )
      }

      let group = this.getLinkedGroupDataByMyCompetenceByGroupId(
        myCompetence,
        groupId
      )

      //toggle
      group.linked = !group.linked
    }
    return this.updateBoard()
  }

  /**
   * Toggles the linked status of a group at a curriculum competence
   * @param {*} myCompetenceId
   * @param {*} groupId
   * @returns promise
   */
  toggleMainGroupCompetence(myCompetenceId, groupId, subCompetenceId) {
    let myCompetence = this.getCurriculumCompetenceById(myCompetenceId)
    if (myCompetence) {
      let group = this.getLinkedGroupDataByMyCompetenceByGroupId(
        myCompetence,
        groupId
      )

      if (group) {
        if (group.competences.length === 0) {
          //prepare first use of data object
          let staticGroupData =
            this.commonStore.getStaticGroupDataByGroupId(groupId)
          if (staticGroupData.competences) {
            group.competences = staticGroupData.competences.map((item) => {
              return new SubCompetence(item.id)
            })
          }
        }

        let subCompetence = group.competences.filter((item) => {
          return item.id === subCompetenceId
        })[0]
        //toggle
        subCompetence.linked = !subCompetence.linked
      }
    }
    return this.updateBoard()
  }

  /**
   * Returns the linked status
   * @param {*} myCompetenceId
   * @param {*} groupId
   * @returns bool
   */
  getGroupLinkedStatus(myCompetenceId, groupId) {
    let myCompetence = this.getCurriculumCompetenceById(myCompetenceId)
    if (myCompetence) {
      let group = this.getLinkedGroupDataByMyCompetenceByGroupId(
        myCompetence,
        groupId
      )
      return group?.linked ?? false
    } else {
      return false
    }
  }

  /**
   * Returns the nr of linked groups
   * @param {*} myCompetenceId
   * @param {*} groupId
   * @returns
   */
  getLinkedGroupNrs(myCompetenceId) {
    let myCompetence = this.getCurriculumCompetenceById(myCompetenceId)
    if (myCompetence) {
      return myCompetence.groups?.filter((item) => {
        return item.linked === true
      }).length
    } else {
      return 0
    }
  }
  /**
   * Returns true if subcompetence is linked
   * @param {*} myCompetenceId
   * @param {*} groupId
   * @param {*} subCompetenceId
   * @returns
   */
  getCompetenceLinkedStatus(myCompetenceId, groupId, subCompetenceId) {
    let myCompetence = this.getCurriculumCompetenceById(myCompetenceId)
    if (myCompetence) {
      let group = this.getLinkedGroupDataByMyCompetenceByGroupId(
        myCompetence,
        groupId
      )
      if (group) {
        return (
          group.competences?.filter((item) => {
            return item.id === subCompetenceId
          })[0]?.linked ?? false
        )
      }
    }
    return false
  }

  getCompetenceHasExampleStatus(myCompetenceId, groupId, subCompetenceId) {
    let myCompetence = this.getCurriculumCompetenceById(myCompetenceId)
    if (myCompetence) {
      let group = this.getLinkedGroupDataByMyCompetenceByGroupId(
        myCompetence,
        groupId
      )
      if (group) {
        return (
          group.competences?.filter((item) => {
            return item.id === subCompetenceId
          })[0]?.example !== '' ?? false
        )
      }
    }
    return false
  }

  /**
   * Adds an example to the linked subcompetence
   * @param {*} myCompetenceId
   * @param {*} groupId
   * @param {*} subCompetenceId
   * @param {*} text
   * @returns
   */
  addCompetenceExample(myCompetenceId, groupId, subCompetenceId, text) {
    let myCompetence = this.getCurriculumCompetenceById(myCompetenceId)
    if (myCompetence) {
      let group = this.getLinkedGroupDataByMyCompetenceByGroupId(
        myCompetence,
        groupId
      )

      if (group) {
        let subCompetence = group.competences.filter((item) => {
          return item.id === subCompetenceId
        })[0]
        subCompetence.example = text
      }
    }
    return this.updateBoard()
  }
  /**
   * Updates a linked subcompetence example with the passed text. Returns a resolved promise when no changes are detected (fixes an 500 error).
   * @param {Curriculum competence text} text
   * @returns
   */
  updateCompetenceExample(myCompetenceId, groupId, subCompetenceId, text) {
    let myCompetence = this.getCurriculumCompetenceById(myCompetenceId)
    if (myCompetence) {
      let group = this.getLinkedGroupDataByMyCompetenceByGroupId(
        myCompetence,
        groupId
      )

      if (group) {
        let subCompetence = group.competences.filter((item) => {
          return item.id === subCompetenceId
        })[0]
        if (subCompetence.example !== text) {
          subCompetence.example = text
          return this.updateBoard()
        }
      }
    }
    return new Promise((resolve, reject) => {
      resolve(true)
    })
  }
  /**
   * Removes the example
   * @param {*} myCompetenceId
   * @param {*} groupId
   * @param {*} subCompetenceId
   * @returns
   */
  deleteCompetenceExample(myCompetenceId, groupId, subCompetenceId) {
    return this.addCompetenceExample(
      myCompetenceId,
      groupId,
      subCompetenceId,
      ''
    )
  }

  /**
   * Returns nr of linked subcompetences
   * @param {*} myCompetenceId
   * @param {*} groupId
   * @param {*} subCompetenceId
   * @returns
   */
  getLinkedCompetenceNrs(myCompetenceId, groupId) {
    let myCompetence = this.getCurriculumCompetenceById(myCompetenceId)
    if (myCompetence) {
      let group = this.getLinkedGroupDataByMyCompetenceByGroupId(
        myCompetence,
        groupId
      )

      if (group) {
        return group.competences?.filter((item) => {
          return item.linked === true
        }).length
      }
    }
    return 0
  }

  /**
   * Returns the curriculum competence object according to the passed id
   * @param {*} myCompetenceId
   * @returns
   */
  getCurriculumCompetenceById(myCompetenceId) {
    return this.currentBoardData.competences.filter((item) => {
      return item.id === myCompetenceId
    })[0]
  }
  /**
   * Returns the group data object
   * @param {*} myCompetence
   * @param {*} groupId
   * @returns
   */
  getLinkedGroupDataByMyCompetenceByGroupId(myCompetence, groupId) {
    return myCompetence.groups.filter((item) => {
      return item.id === groupId
    })[0]
  }
  /**
   * Returns the subcompetence example
   * @param {*} myCompetenceId
   * @param {*} groupId
   * @param {*} subCompetenceId
   * @returns
   */
  getCompetenceExampleById(myCompetenceId, groupId, subCompetenceId) {
    let myCompetence = this.getCurriculumCompetenceById(myCompetenceId)
    if (myCompetence) {
      let group = this.getLinkedGroupDataByMyCompetenceByGroupId(
        myCompetence,
        groupId
      )

      if (group) {
        return group.competences.filter((item) => {
          return item.id === subCompetenceId
        })[0].example
      }
    }
    return ''
  }

  responseMessage = (response) => {
    this.messageList.push({
      msg:
        (response.statusCode ? response.statusCode + ' ' : '') +
        (response.message && response.message),
      type: 'info',
    })

    //check message response for token freshness
    this.commonStore.evaluateResponseMessage(response)
  }

  requestError = (error) => {
    this.messageList.push({ msg: error.message, type: 'danger' })
  }
}

//objects
function MyCompetence() {
  this.body = ''
  this.id = uniqueId()
  this.groups = []
  this.cardNr = 0
}

function Group(id) {
  this.id = id
  this.linked = false
  this.competences = []
}

function SubCompetence(id) {
  this.id = id
  this.linked = false
  this.example = ''
}
