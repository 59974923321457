import blackCheckmark from '../../../assets/icon-check-black.svg'
import { useStores } from '../../stores'
import { POPUP_TYPE } from '../../utils/Enums'
import { BigBottomButton } from './BigBottomButton'
import CompetenceExampleView from './CompetenceExampleView'
import './PopoverGroupCompetence.scss'
import { PopoverGroupCompetenceItem } from './PopoverGroupCompetenceItem'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default observer(function PopoverGroupCompetence(props) {
  const { t } = useTranslation(['common', 'board'], {
    useSuspense: true,
  })
  const { commonStore, boardStore, popupMessageStore, walkthroughStore } =
    useStores()
  const popoverRef = useRef()
  const {
    position,
    onClose,
    myCompetenceId,
    groupId,
    popupClass = '',
    onDeleteExample,
  } = props
  const [showExample, setShowExample] = useState(false)

  //holds the current viewed example
  const [exampleId, setExampleId] = useState('')
  const [compentenceExampleViewPosition, setCompentenceExampleViewPosition] =
    useState(null)
  const [inRightViewPort, setInRightViewPort] = useState(false)
  const groupData = commonStore.getStaticGroupDataByGroupId(groupId)

  const onToggleCompetence = useCallback(
    (subCompetenceId) => {
      //console.log(subCompetenceId)
      boardStore.toggleMainGroupCompetence(
        myCompetenceId,
        groupId,
        subCompetenceId
      )
      walkthroughStore.nextStep()
    },
    [boardStore, groupId, myCompetenceId, walkthroughStore]
  )

  const closeExample = useCallback(() => {
    setShowExample(false)
  }, [])

  const onClickExampleButton = useCallback(
    (subCompetenceId, hasExample, position) => {
      if (hasExample) {
        //toggle the example here
        setShowExample(!showExample)
        setExampleId(subCompetenceId)
        setCompentenceExampleViewPosition(position)
      } else {
        setShowExample(false)
        //no example add an example here}
        popupMessageStore.showPopupMessage({
          type: POPUP_TYPE.NEW_COMPETENCE_EXAMPLE,
          description: 'competence example popup',
          myCompetenceId: myCompetenceId,
          groupId: groupId,
          subCompetenceId: subCompetenceId,
        })
      }
    },
    [popupMessageStore, myCompetenceId, groupId, showExample]
  )

  const deleteExample = useCallback(() => {
    onDeleteExample({
      myCompetenceId,
      groupId,
      subCompetenceId: exampleId,
    })
  }, [onDeleteExample, exampleId, groupId, myCompetenceId])

  const editExample = useCallback(() => {
    popupMessageStore.showPopupMessage({
      type: POPUP_TYPE.EDIT_COMPETENCE_EXAMPLE,
      description: 'competence example popup',
      body: boardStore.getCompetenceExampleById(
        myCompetenceId,
        groupId,
        exampleId
      ),
      myCompetenceId: myCompetenceId,
      groupId: groupId,
      subCompetenceId: exampleId,
    })
  }, [exampleId, groupId, myCompetenceId, popupMessageStore, boardStore])

  useEffect(() => {
    if (commonStore.viewPortSplitBoundary > 0) {
      var bounding = popoverRef.current.getBoundingClientRect()
      setInRightViewPort(bounding.right > commonStore.viewPortSplitBoundary)
    }
  }, [commonStore.viewPortSplitBoundary])

  return (
    <React.Fragment>
      {position && groupData && (
        <div
          ref={popoverRef}
          className={
            'group-competence-popover-container' +
            (inRightViewPort ? ' left' : '')
          }
          style={{ top: position.y + 'px', left: position.x + 'px' }}
        >
          <div className={'group-competence-popover ' + popupClass}>
            <div
              className={
                'group-competence-popover-background ' + groupData.color
              }
            ></div>
            <div className='group-competence-popover-box-shadow '></div>

            <div className='group-competence-popover-inner'>
              <div className='group-competence-popover-header'>
                <div className='group-competence-popover-header-c2a d-flex'>
                  {t('board:message.c2aLinkCompetence')}
                </div>
                <div className='group-competence-popover-header-title'>
                  {groupData.title}
                </div>
              </div>
              <div className='group-competence-popover-body'>
                {groupData.competences.map((item, index) => {
                  let selected = boardStore.getCompetenceLinkedStatus(
                    myCompetenceId,
                    groupId,
                    item.id
                  )
                  let hasExample = boardStore.getCompetenceHasExampleStatus(
                    myCompetenceId,
                    groupId,
                    item.id
                  )
                  return (
                    <PopoverGroupCompetenceItem
                      alignLeft={inRightViewPort}
                      key={index}
                      data={item}
                      selected={selected}
                      hasExample={hasExample}
                      onSelect={onToggleCompetence}
                      onClickExample={onClickExampleButton}
                      opened={showExample && item.id === exampleId}
                    />
                  )
                })}
              </div>
              <div className='group-competence-popover-footer'>
                <BigBottomButton
                  onClick={onClose}
                  svgIcon={blackCheckmark}
                  buttonClass='big-confirm-button '
                  label={t('common:buttonLabels.confirm')}
                />
              </div>
            </div>
            {showExample && (
              <CompetenceExampleView
                {...props}
                exampleId={exampleId}
                position={compentenceExampleViewPosition}
                onDeleteExample={deleteExample}
                onEditExample={editExample}
                onHideSelf={closeExample}
                isValid={boardStore.getCompetenceHasExampleStatus(
                  myCompetenceId,
                  groupId,
                  exampleId
                )}
              />
            )}
            {showExample && (
              <div className='competence-example-background-cover'></div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  )
})
