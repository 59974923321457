import edit from './../../../assets/icon-edit.svg'
import trash from './../../../assets/icon-trash.svg'
import { OPTION_TYPE } from './CompetenceCard'
import './OptionsMenu.scss'
import { SVGButton } from './SVGButton'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const OptionsMenu = ({ show, onOption }) => {
  const { t } = useTranslation(['common', 'board'], {
    useSuspense: true,
  })

  return (
    <div className={'competence-options-menu ' + (show ? 'opened' : '')}>
      <SVGButton
        icon={edit}
        tt={t('board:tooltips.editCompetence')}
        onClick={() => onOption(OPTION_TYPE.EDIT)}
        buttonClass='btn-dcp-option-item-button'
        label={t('common:buttonLabels.edit')}
      />
      <SVGButton
        icon={trash}
        tt={t('board:tooltips.deleteCompetence')}
        onClick={() => onOption(OPTION_TYPE.DELETE)}
        buttonClass='btn-dcp-option-item-button'
        label={t('common:buttonLabels.delete')}
      />
    </div>
  )
}
