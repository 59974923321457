import iconOptions from '../../../assets/3dots.svg'
import './OptionsButton.scss'

export const OptionsButton = (props) => {
  var { onClick = () => {}, tt = '' } = props

  return (
    <div
      role='button'
      onClick={onClick}
      title={tt}
      className='btn btn-svg-icon options-button'
    >
      <img src={iconOptions} alt='options' />
    </div>
  )
}
