import Singleton from '../utils/Singleton'
import axios from 'axios'

export class WebService extends Singleton {
  constructor() {
    super()

    this.basePath = process.env.REACT_APP_REST_API_BASEPATH
    this.host = process.env.REACT_APP_HOST
    this.jwt = ''
    this.refreshToken = ''
    //timeout
    this.timeOutDelay = 5000
  }

  // async getPDF(boardId) {
  //   boardId = '1'
  //   return this.doRequest({
  //     type: 'GET',
  //     data: { cmd: 1, id: boardId },
  //     basePath: window.location.pathname,
  //     url: '',
  //     shouldRetry: false,
  //   })
  // }

  async getStandardCompetences() {
    let url = 'retrieve-competences/'

    return this.doRequest({
      type: 'GET',
      data: null,
      url: url,
      shouldRetry: false,
    })
  }

  /**
   *
   * @param {*} title title of the competention board
   * @param {*} sector id of labour market sector
   * @param {*} educationLevel id of education level
   * @returns id of the board
   */
  async createNewBoard(data) {
    let url = 'create-board/'

    return this.doRequest({
      type: 'POST',
      data: data,
      url: url,
      shouldRetry: false,
    })
  }

  async getLanguage() {
    let url = 'get-language/'

    return this.doRequest({
      type: 'GET',
      data: null,
      url: url,
      shouldRetry: false,
    })
  }

  async getBoardDataById(boardId) {
    let url = 'retrieve-board/'

    return this.doRequest({
      type: 'GET',
      data: { id: boardId },
      url: url,
      shouldRetry: false,
    })
  }

  async updateBoardData(data) {
    let url = 'update-board/'

    return this.doRequest({
      type: 'POST',
      data: data,
      url: url,
      shouldRetry: false,
    })
  }

  async doRequest(userOptions) {
    const defaultOptions = {
      type: 'GET',
      data: null,
      dataType: 'json',
      url: '',
      retries: 0,
      timeout: this.timeOutDelay,
      basePath: this.basePath,
      shouldRetry: true,
      token: null,
    }
    const options = { ...defaultOptions, ...userOptions }

    // Process the data.
    if (process.env.REACT_APP_DEBUG) console.log(options.url, options.data)
    //if POST
    const body = options.data
    // const body = options.type !== 'GET' ? this.prepareBody(options.data) : null

    //if GET
    const params = options.type === 'GET' ? options.data : null

    // Determine which token to use. postRefreshJwt provides its own token. Default
    // to the regular JWT.
    const token =
      options.data && 'token' in options.data
        ? String(options.data.token)
        : options.token
        ? options.token
        : this.jwt

    const headers = options.headers ?? {}

    // const headers = {
    //   'Access-Control-Allow-Origin': '*',
    //   'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    // }

    if (token) {
      headers.Authorization = `Bearer ${token}`
    }

    return new Promise((resolve, reject) => {
      axios({
        method: options.type,
        url: this.host + options.basePath + options.url,
        data: body,
        params,
        headers,
        responseType: 'json',
        timeout: options.timeout,
      })
        .then((response) => {
          const data = response.data
          if (process.env.REACT_APP_DEBUG)
            console.log('do request response:', data)

          resolve(data)
          return null
        })
        .catch((error) => {
          if (process.env.REACT_APP_DEBUG)
            console.error('do request error:', error)

          const failed = () => {
            reject(error)
            return
          }

          // We'll try to retry the request if it failed.
          /*if (options.retries < this.maxRetries && options.shouldRetry) {
            options.retries++
            this.doRequest<T>({
              ...userOptions,
              retries: options.retries
            })
              .then(resolve)
              .catch(failed)

            return
          }*/

          // Retried the maximum number of times, let the UI know. Or if the caller
          // handles promise rejection properly, just delegate.
          failed()
        })
    })
  }

  prepareBody(data) {
    if (data === null || data instanceof FormData) {
      return data
    }

    const fd = new FormData()
    Object.keys(data).forEach((k) =>
      this.prepareValue(k, data[k]).map(({ key, value }) =>
        fd.append(key, value)
      )
    )

    return fd
  }

  prepareValue(key, value) {
    console.log('prepare', { key, value })
    if (value === null || typeof value === 'undefined') {
      return [{ key, value: '' }]
    } else if (typeof value === 'number') {
      return [{ key, value: String(value) }]
    } else if (typeof value === 'boolean') {
      return [{ key, value: value ? 'true' : 'false' }]
    } else if (typeof value === 'string') {
      return [{ key, value }]
    } else if (!Array.isArray(value)) {
      const result = []
      Object.entries(value).forEach(([k, v]) =>
        v.forEach((entry) => {
          result.push({ key: `${key}[${k}][]`, value: String(entry) })
        })
      )

      return result
    }

    // If we get here, it's an array of numbers.
    return value.map((num) => ({ key: key + '[]', value: String(num) }))
  }
}
