import React, { useEffect } from 'react'
import Background from '../components/common/Background'
import { useStores } from '../stores'

import './Page.scss'

export function Page(props) {
  const { commonStore } = useStores()
  const wide = 'wide' in props && props.wide
  const narrow = !wide && 'narrow' in props && props.narrow

  const {
    logo = null,
    splitOffset: splitBoundaryFromRightEdgeOffset = window.innerWidth * 0.5,
  } = props

  //calculates the split left/right boundary of the window..
  const calcViewPortSplitBoundary = () => {
    let d = window.innerWidth - splitBoundaryFromRightEdgeOffset
    // console.log(d)
    commonStore.setViewPortSplitBoundary(d)
  }

  useEffect(() => {
    calcViewPortSplitBoundary()
  })

  useEffect(() => {
    window.addEventListener('resize', calcViewPortSplitBoundary)
    return () => {
      window.removeEventListener('resize', calcViewPortSplitBoundary)
    }
  })

  return (
    <div className='dcp-page container-fluid'>
      <Background className={props.bgClassName || ''}>{logo}</Background>
      <div className='row justify-content-center'>{props.children}</div>
    </div>
  )
}
