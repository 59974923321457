import React, { useCallback, useState } from 'react'

import { observer } from 'mobx-react'
import { useStores } from '../../stores'

import { useTranslation } from 'react-i18next'

import './PopupNewBoard.scss'
import { useNavigate } from 'react-router-dom'

import { stripHtml } from '../../utils/stripHtml'
import { BigBottomButton } from './BigBottomButton'

export default observer(function PopupNewBoard() {
  const { commonStore, boardStore, popupMessageStore } = useStores()

  const { t, ready } = useTranslation(['common', 'board', 'popup'], {
    useSuspense: true,
  })
  const navigate = useNavigate()

  const [boardTitle, setBoardTitle] = useState('')
  const [sector, setSector] = useState('')
  const [educationLevel, setEducationLevel] = useState('')

  const isFormComplete = useCallback(() => {
    return boardTitle !== '' && sector !== '' && educationLevel !== ''
  }, [boardTitle, sector, educationLevel])

  const onSubmit = () => {
    console.log('submit', { boardTitle, sector, educationLevel })
    boardStore
      .createNewBoard(stripHtml(boardTitle), sector, educationLevel)
      .then(() => {
        popupMessageStore.clearPopupMessage()
        navigate('/myboard/' + boardStore.newBoardId)
      })
  }

  const onTitleChange = useCallback(
    (e) => {
      setBoardTitle(e.target.value)
    },
    [setBoardTitle]
  )
  const onSectorChange = useCallback(
    (e) => {
      setSector(e.target.value)
    },
    [setSector]
  )
  const onEducationLevelChange = useCallback(
    (e) => {
      setEducationLevel(e.target.value)
    },
    [setEducationLevel]
  )

  if (ready) {
    return (
      <div className='popup-new-board-container dcp-popup'>
        <div className='row justify-content-center'>
          <div className='col'>
            <h5
              className='c2a-label'
              dangerouslySetInnerHTML={{
                __html: t('popup:newBoard.title'),
              }}
            ></h5>
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-12'>
            <form>
              <div className='form-group'>
                <label htmlFor='exampleInputEmail1'>
                  {t('popup:newBoard.form.input.title.label')}
                </label>
                <input
                  value={boardTitle ?? ''}
                  type='text'
                  onChange={onTitleChange}
                  className='form-control'
                  id='inputTitle'
                  placeholder={t('popup:newBoard.form.input.title.placeholder')}
                />
              </div>

              <div className='form-group'>
                <label htmlFor='exampleFormControlSelect1'>
                  {t('popup:newBoard.form.input.eductionalLevel.label')}
                </label>
                <select
                  onChange={onEducationLevelChange}
                  defaultValue={educationLevel ?? ''}
                  className='form-select'
                  id='exampleFormControlSelect1'
                >
                  <option value='' disabled>
                    {t('popup:newBoard.form.input.eductionalLevel.placeholder')}
                  </option>
                  {commonStore.educationLevels.map((item, key) => {
                    return (
                      <option key={key} value={key}>
                        {item.title}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className='form-group'>
                <label htmlFor='exampleFormControlSelect1'>
                  {t('popup:newBoard.form.input.sector.label')}
                </label>
                <select
                  onChange={onSectorChange}
                  defaultValue={sector ?? ''}
                  className='form-select'
                  id='exampleFormControlSelect1'
                >
                  <option value='' disabled>
                    {t('popup:newBoard.form.input.sector.placeholder')}
                  </option>
                  {commonStore.sectors.map((item, key) => {
                    return (
                      <option key={key} value={key}>
                        {item.title}
                      </option>
                    )
                  })}
                </select>
              </div>
            </form>
          </div>
        </div>
        <div className='row justify-content-center mt-4'>
          <div className='col-12'>
            <div className='dcp-popup-button-footer'>
              <BigBottomButton
                onClick={isFormComplete() ? onSubmit : () => {}}
                buttonClass='big-confirm-button '
                label={t('common:buttonLabels.submit')}
                disabled={!isFormComplete()}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return null
})
