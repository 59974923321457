import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride'
import { useStores } from '../../stores'

import './Walkthrough.scss'

import { mainStyles } from './joyride.js'
import { WALKTHROUGH_CONTENT_TYPE } from './joyride.js'

export default observer(function Walkthrough() {
  const { t } = useTranslation('walkthrough', {
    useSuspense: true,
  })
  const locale = t('joyrideUI', { returnObjects: true })
  const { walkthroughStore, commonStore } = useStores()

  const clickStepOptions = useMemo(() => {
    return {
      hideFooter: true,
      hideCloseButton: true,
      spotlightClicks: true,
    }
  }, [])

  const htmlContent = (content) => {
    return (
      <React.Fragment>
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </React.Fragment>
    )
  }

  const stepsBoard = useMemo(() => {
    return [
      {
        target: 'body',
        title: t('main.first.title'),
        content: htmlContent(t('main.first.body')),
        placement: 'center',
      },
      {
        target: '.add-competence-button',
        content: t('board.step20.body'),
        placement: 'right',
        ...clickStepOptions,
        disableScrolling: false,
      },
      {
        target: '.competence-card',
        content: t('board.step30.body'),
      },
      {
        target: '.linkgroup-button',
        content: t('board.step40.body'),
        ...clickStepOptions,
      },
      {
        target: '.linkgroup-popover',
        content: t('board.step42.body'),
        placement: 'top',
      },
      {
        target: '.link-group-item-container.blue',
        content: t('board.step44.body'),
        ...clickStepOptions,
      },
      {
        target: '.link-status-indicator-container',
        content: t('board.step46.body'),
      },
      {
        target: '.link-status-indicator-button.blue',
        content: t('board.step50.body'),
        ...clickStepOptions,
      },
      {
        target: '.group-competence-popover-container',
        content: t('board.step52.body'),
        placement: 'right',
      },
      {
        target: '.competence-item-container',
        content: t('board.step54.body'),
        ...clickStepOptions,
      },
      {
        target: '.example-button',
        content: htmlContent(t('board.step60.body')),
      },
      {
        target: '.big-confirm-button',
        content: t('board.step64.body'),
        ...clickStepOptions,
      },
      {
        target: '.options-button',
        content: t('board.step66.body'),
      },
      {
        target: '#copy-url-button',
        title: t('board.step10.title'),
        content: htmlContent(t('board.step10.body')),
        placement: 'bottom',
      },
      {
        target: '#pdf-link',
        title: t('board.step80.title'),
        content: t('board.step80.body'),
        placement: 'bottom',
      },
      {
        target: 'body',
        title: t('board.step70.title'),
        content: htmlContent(t('board.step70.body')),
        placement: 'center',
      },
      {
        target: 'body',
        title: t('main.last.title'),
        content: htmlContent(t('main.last.body')),
        placement: 'center',
      },
    ]
  }, [clickStepOptions, t])

  const stepsFramework = useMemo(() => {
    return [
      {
        target: 'body',
        title: t('framework.start.title'),
        content: t('framework.start.body'),
        placement: 'center',
      },
      {
        target: '.tab-button',
        content: t('framework.step2.body'),
      },
      {
        target: '.main-group-item-container.blue',
        content: t('framework.step10.body'),
        ...clickStepOptions,
        placement: 'top-start',
      },
      {
        target: '.group-item-competence-container.blue',
        content: t('framework.step20.body'),
        ...clickStepOptions,
        placement: 'top-end',
      },
      {
        target: '.group-item-competence-container.blue.selected',
        content: t('framework.step24.body'),
        placement: 'top',
      },
      {
        target: '.group-item-competence-example-body-nav',
        content: t('framework.step26.body'),
        placement: 'left',
      },
    ]
  }, [clickStepOptions, t])

  const stepsMenu = useMemo(() => {
    return [
      {
        target: '.menu-button',
        disableBeacon: true,
        showProgress: false,
        content: t('board.step90.body'),
        placement: 'bottom-start',
      },
    ]
  }, [t])

  const stepsReflect = useMemo(() => {
    return [
      {
        target: 'body',
        disableBeacon: true,
        showProgress: false,
        title: t('board.step70.title'),
        content: htmlContent(t('board.step70.body')),
        placement: 'center',
      },
    ]
  }, [t])

  /**add the stepdata to the store to be able to start tours from everywhere */
  useEffect(() => {
    console.log('changing', commonStore.lang)
    walkthroughStore.addStepsToLibrary(
      WALKTHROUGH_CONTENT_TYPE.BOARD,
      stepsBoard
    )
    walkthroughStore.addStepsToLibrary(
      WALKTHROUGH_CONTENT_TYPE.FRAMEWORK,
      stepsFramework
    )
    walkthroughStore.addStepsToLibrary(WALKTHROUGH_CONTENT_TYPE.MENU, stepsMenu)
    walkthroughStore.addStepsToLibrary(
      WALKTHROUGH_CONTENT_TYPE.REFLECT,
      stepsReflect
    )
  }, [stepsBoard, stepsFramework, stepsMenu, walkthroughStore, commonStore.lang, stepsReflect])

  /** auto start framework tour */
  useEffect(() => {
    if (
      commonStore.sideBarRightOpened &&
      !walkthroughStore.walkthroughIsRunning &&
      !walkthroughStore.getVisited(WALKTHROUGH_CONTENT_TYPE.FRAMEWORK)
    ) {
      walkthroughStore.start(WALKTHROUGH_CONTENT_TYPE.FRAMEWORK)
    }
  }, [commonStore.sideBarRightOpened, stepsFramework, walkthroughStore])

  /** auto start board tour */
  useEffect(() => {
    if (
      !walkthroughStore.getVisited(WALKTHROUGH_CONTENT_TYPE.BOARD) &&
      !walkthroughStore.walkthroughIsRunning
    ) {
      walkthroughStore.start(WALKTHROUGH_CONTENT_TYPE.BOARD)
    }
  }, [stepsBoard, walkthroughStore])

  /** handle step */
  const handleWalkthroughCallBack = (state) => {
    const { action, index, status, type } = state

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      const nextIndex = index + (action === ACTIONS.PREV ? -1 : 1)

      setTimeout(() => {
        walkthroughStore.setStepIndex(nextIndex)
      }, 200)
    } else if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
      index === walkthroughStore.steps.length
    ) {
      // Need to set our running state to false, so we can restart if we click start again.
      //walkthroughStore.setStepIndexBoard(0)
      walkthroughStore.setRunWalkthrough(false)
      walkthroughStore.setWalkthroughIsRunning(false)
      walkthroughStore.setVisited(walkthroughStore.currentType)

      if (
        walkthroughStore.currentType === WALKTHROUGH_CONTENT_TYPE.BOARD &&
        !walkthroughStore.getVisited(WALKTHROUGH_CONTENT_TYPE.MENU)
      ) {
        walkthroughStore.setSteps(stepsMenu)
        walkthroughStore.start(WALKTHROUGH_CONTENT_TYPE.MENU)
      }
    }
  }

  return (
    <Joyride
      beaconComponent={null}
      callback={handleWalkthroughCallBack}
      continuous={true}
      debug={process.env.REACT_APP_DEBUG === 'true'}
      disableCloseOnEsc={true}
      disableScrolling={true}
      disableOverlayClose={true}
      disableBeacon={true}
      hideBackButton={true}
      hideCloseButton={true}
      key={Date.now()}
      locale={locale}
      run={walkthroughStore.run}
      showProgress={true}
      showSkipButton={true}
      stepIndex={walkthroughStore.stepIndex}
      steps={walkthroughStore.steps}
      styles={mainStyles}
    />
  )
})
