import logo from '../../../assets/logo-small-white.svg'
import { useStores } from '../../stores'
import { MainGroupItem } from './MainGroupItem'
import './MainTargetsSideBar.scss'
import { t } from 'i18next'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default observer(function MainGroupSideBar() {
  const [selectedItemIndex, setSelectedItemIndex] = useState(null)
  const { commonStore, walkthroughStore } = useStores()
  const { t } = useTranslation(['framework'], { useSuspense: true })

  const toggleBar = useCallback(() => {
    commonStore.setSideBarOpen(!commonStore.sideBarRightOpened)
  }, [commonStore])

  const onSelectGroup = useCallback(
    (val) => {
      setSelectedItemIndex(val ?? null)
      if (!commonStore.sideBarRightOpened) {
        commonStore.setSideBarOpen(true)
      }
      walkthroughStore.nextStep()
    },
    [commonStore, walkthroughStore]
  )

  useEffect(() => {
    if (!commonStore.sideBarRightOpened) {
      setSelectedItemIndex(null)
    }
  }, [commonStore.sideBarRightOpened])

  return (
    commonStore.showSideBarRight && (
      <div
        className={
          'main-targets-container ' +
          (!commonStore.sideBarRightOpened ? 'closed' : '')
        }
      >
        {commonStore.sideBarRightItems && (
          <div
            role='button'
            onClick={commonStore.sideBarRightItems ? toggleBar : () => {}}
            className={
              'tab-button' + (!commonStore.sideBarRightOpened ? ' closed' : '')
            }
          ></div>
        )}
        <div
          className='header'
          onClick={commonStore.sideBarRightItems ? toggleBar : () => {}}
          role={commonStore.sideBarRightItems ? 'button' : ''}
        >
          <img src={logo} className='logo' alt='logo' />
        </div>
        <div className='main-targets-body'>
          {commonStore.sideBarRightItems && (
            <React.Fragment>
              <div
                className='main-targets-body-title'
                dangerouslySetInnerHTML={{
                  __html: t('title'),
                }}
              ></div>
              <ul className='main-targets-list'>
                {commonStore.standardCompetences.map((item, key) => {
                  return (
                    <MainGroupItem
                      data={item}
                      key={key}
                      index={key}
                      selected={selectedItemIndex === key}
                      selectedItemIndex={selectedItemIndex}
                      onSelect={onSelectGroup}
                    />
                  )
                })}
              </ul>
              {commonStore.sideBarRightOpened && (
                <div
                  className='citation reveal'
                  dangerouslySetInnerHTML={{
                    __html: t('citation.short'),
                  }}
                ></div>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    )
  )
})
