export default class Singleton {
  /**
   * The singleton instance.
   */
  static _instance: any

  /**
   * Get the singleton instance.
   */
  static getInstance() {
    // Ugly hack alert! See https://github.com/Microsoft/TypeScript/issues/5863#issuecomment-365918436
    const derived = this

    return derived._instance || (derived._instance = new this())
  }
}
