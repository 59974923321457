// @ts-nocheck
import i18n from 'i18next'
//import detector from "i18next-browser-languagedetector";
import httpApi from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

// translations are already at
// '../public/locales/nl-nl/translation.json'
// which is the default for the xhr backend to load from

//.use(detector)
i18n
  .use(httpApi)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'nl-nl',
    fallbackLng: 'nl-nl', // use en if detected lng is not available
    lowerCaseLng: true,
    load: 'currentOnly',

    backend: {
      loadPath:
        process.env.PUBLIC_URL +
        '/locales/{{lng}}/{{ns}}.json?nocache=' +
        new Date().getTime(),
    },

    interpolation: {
      escapeValue: false, // react already safes from xss
      skipOnVariables: false,
    },
    react: {
      // wait: true,
      useSuspense: true,
      omitBoundRerender: false,
    },
  })

export default i18n
