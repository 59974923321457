import { faExclamationTriangle } from '@fortawesome//free-solid-svg-icons/faExclamationTriangle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'

import './ErrorBoundary.scss'

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props)

    this.state = {
      didCatch: false,
    }
  }

  componentDidCatch(error, info) {
    if (process.env.NODE_ENV === 'development') {
      console.error('An error was caught by the ErrorBoundary.', error, info)
    } else {
      // Send to Sentry.
      //Sentry.captureException(error)
    }

    this.setState({ didCatch: true })
  }

  render() {
    if (!this.state.didCatch) {
      return <React.Fragment>{this.props.children}</React.Fragment>
    }

    // This text is not translated. Probably better to let this component depend
    // on as few external things as possible.
    return (
      <div className='error-container'>
        <div className='error-container-icon'>
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </div>

        <h1 className='error-container-heading'>
          Something went wrong - Er ging iets mis
        </h1>
        <p>
          It seems we&apos;ve encountered a problem that prevented the app from
          carrying on. Don&apos;t worry, your data is not lost and you can
          restart the app and continue.
        </p>
        <p>
          Er heeft zich een probleem voorgedaan waardoor de applicatie niet meer
          verder gaat. Geen nood. Je data is niet verloren gegaan. Je kunt de
          app herstarten en weer verder gaan.
        </p>

        <div className='btn btn-primary' onClick={this.reload}>
          Restart - Herstart
        </div>
      </div>
    )
  }

  reload() {
    //location.href = '/app'
    // eslint-disable-next-line no-restricted-globals
    location.reload()
  }
}
