import closeIcon from '../../../assets/icon-close-white.svg'
import showExampleIcon from '../../../assets/icon-eye.svg'
import addExampleIcon from '../../../assets/icon-plus-orange.svg'
import './PopoverGroupCompetenceItem.scss'
import { SVGButton } from './SVGButton'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const PopoverGroupCompetenceItem = (props) => {
  const { t } = useTranslation(['board'], { useSuspense: true })
  const itemRef = useRef()
  const {
    data,
    hasExample,
    onClickExample,
    onSelect,
    selected = false,
    opened = false,
    style = {},
    alignLeft = false,
  } = props

  const onToggle = () => {
    onSelect(data.id)
  }

  const onExampleButton = useCallback(
    (evt) => {
      let position = {
        x: itemRef.current.offsetLeft + evt.currentTarget.offsetLeft,
        y: itemRef.current.offsetTop + evt.currentTarget.offsetTop,
      }
      onClickExample(data.id, hasExample, position)
    },
    [data, hasExample, onClickExample]
  )

  return (
    <div
      className={'competence-item-container' + (alignLeft ? ' left' : '')}
      ref={itemRef}
    >
      <div
        role={'button'}
        onClick={onToggle}
        className={
          'competence-item' +
          (selected ? ' selected' : '') +
          (opened ? ' opened' : '')
        }
        style={style}
      >
        <div className='competence-item-inner'>
          <div className='competence-item-title'>{data.title}</div>
        </div>
      </div>
      {selected && (
        <SVGButton
          icon={
            hasExample ? (opened ? closeIcon : showExampleIcon) : addExampleIcon
          }
          tt={
            hasExample
              ? opened
                ? t('tooltips.hideExample')
                : t('tooltips.showExample')
              : t('tooltips.addExample')
          }
          buttonClass={
            'btn-dcp-icon-circle example-button' + (hasExample ? '' : ' add')
          }
          onClick={onExampleButton}
        />
      )}
    </div>
  )
}
