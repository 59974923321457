import downArrow from '../../../assets/icon-arrow-down.svg'
import { useStores } from '../../stores'
import { CloseButton } from './CloseButton'
import MainGroupCompetenceItem from './MainGroupCompetenceItem'
import './MainGroupItem.scss'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const MainGroupItem = (props) => {
  const { t } = useTranslation(['framework'], { useSuspense: true })
  const { data, onSelect, selected, selectedItemIndex, index } = props
  const { commonStore, walkthroughStore } = useStores()
  const [notSelectedClass, setNotSelectedClass] = useState('')
  const [selectedCompetenceIndex, setSelectedCompetenceIndex] = useState(null)

  const onClick = () => {
    onSelect(index)
  }

  const onClose = () => {
    onSelect(null)
    setSelectedCompetenceIndex(null)
  }

  // const onCloseCompetence = () => {
  //   setSelectedCompetenceIndex(null)
  // }

  const onSelectCompetence = useCallback(
    (val) => {
      setSelectedCompetenceIndex(val ?? null)
      walkthroughStore.nextStep()
    },
    [walkthroughStore]
  )

  useEffect(() => {
    if (!selected) {
      setSelectedCompetenceIndex(null)
    }
  }, [selected])

  useEffect(() => {
    if (selectedItemIndex !== null) {
      if (selectedItemIndex < index) {
        //above selected item
        setNotSelectedClass('selection-above')
      } else if (selectedItemIndex > index) {
        //below selected item
        setNotSelectedClass('selection-below')
      } else {
        //item is selected
        setNotSelectedClass('')
      }
    } else {
      //nothing selected
      //console.log('nothing selected')
      setNotSelectedClass('')
    }
  }, [selectedItemIndex, setNotSelectedClass, index])

  return (
    <li
      role={!selected ? 'button' : ''}
      onClick={!selected ? onClick : null}
      className={
        'main-group-item-container ' +
        data.color +
        ' ' +
        (selected ? 'selected' : notSelectedClass) +
        (!commonStore.sideBarRightOpened ? ' closed' : '')
      }
    >
      {!commonStore.sideBarRightOpened && (
        <div className={'group-icon-container ' + data.color}></div>
      )}
      {commonStore.sideBarRightOpened && (
        <div className='main-group-item-inner'>
          <div
            className={
              'group-item-header' +
              (selectedCompetenceIndex !== null ? ' hide' : '')
            }
          >
            <div className='group-item-title '>{data.title}</div>
            {selected && selectedCompetenceIndex === null && (
              <CloseButton onClose={onClose} />
            )}
            {!selected && (
              <img className='arrow-icon' src={downArrow} alt='down arrow' />
            )}
          </div>

          <div
            className='group-item-description'
            dangerouslySetInnerHTML={{
              __html: data.description,
            }}
          ></div>

          {selected && selectedCompetenceIndex === null && (
            <div className='group-item-label'>{t('labels.subCompetences')}</div>
          )}

          {data.competences &&
            selected &&
            data.competences.map((item, index) => {
              return (
                <MainGroupCompetenceItem
                  color={data.color}
                  onSelect={onSelectCompetence}
                  selected={selectedCompetenceIndex === index}
                  selectedItemIndex={selectedCompetenceIndex}
                  index={index}
                  data={item}
                  key={index}
                  style={selected ? { animationDelay: index * 0.1 + 's' } : {}}
                />
              )
            })}
        </div>
      )}
      &nbsp;
    </li>
  )
}
