import React, { Component } from "react";

export default class Spinner extends Component {
  render() {
    return (
      <div
        style={{
          position: "fixed",
          left: "0px",
          right: "0px",
          top: "0px",
          bottom: "0px",
          zIndex: 9999,
        }}
      >
        <Loader />
      </div>
    );
  }
}

const Loader = () => {
  return (
    <div>
      <style
        dangerouslySetInnerHTML={{
          __html: `.lds-ring {
                      display: inline-block;
                      width: 64px;
                      height: 64px;
                      position: absolute;
                      left:50%;
                      top:50%; 
                      margin-left:-32px;
                      margin-top:-32px;
                    }
                    .lds-ring div {
                      box-sizing: border-box;
                      display: block;
                      position: absolute;
                      width: 51px;
                      height: 51px;
                      margin: 6px;
                      border: 6px solid #4da542;
                      border: 6px solid #00a1de;
                      border-radius: 50%;
                      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                      border-color: #4da542 transparent transparent transparent;
                      border-color: #00a1de transparent transparent transparent;
                    }
                    .lds-ring div:nth-child(1) {
                      animation-delay: -0.45s;
                    }
                    .lds-ring div:nth-child(2) {
                      animation-delay: -0.3s;
                    }
                    .lds-ring div:nth-child(3) {
                      animation-delay: -0.15s;
                    }
                    @keyframes lds-ring {
                      0% {
                        transform: rotate(0deg);
                      }
                      100% {
                        transform: rotate(360deg);
                      }
                    }  `,
        }}
      />

      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

const LoaderGrid = () => {
  return (
    <div>
      <style
        dangerouslySetInnerHTML={{
          __html: `.lds-grid {
                      display: inline-block;
                      width: 64px;
                      height: 64px;
                      position: absolute;
                      left:50%;
                      top:50%; 
                      margin-left:-32px;
                      margin-top:-32px;
                    }
                    .lds-grid div {
                      position: absolute;
                      width: 13px;
                      height: 13px;
                      border-radius: 50%;
                      background: #4da542;
                      background: #00a1de;
                      animation: lds-grid 1.2s linear infinite;
                    }
                    .lds-grid div:nth-child(1) {
                      top: 6px;
                      left: 6px;
                      animation-delay: 0s;
                    }
                    .lds-grid div:nth-child(2) {
                      top: 6px;
                      left: 26px;
                      animation-delay: -0.4s;
                    }
                    .lds-grid div:nth-child(3) {
                      top: 6px;
                      left: 45px;
                      animation-delay: -0.8s;
                    }
                    .lds-grid div:nth-child(4) {
                      top: 26px;
                      left: 6px;
                      animation-delay: -0.4s;
                    }
                    .lds-grid div:nth-child(5) {
                      top: 26px;
                      left: 26px;
                      animation-delay: -0.8s;
                    }
                    .lds-grid div:nth-child(6) {
                      top: 26px;
                      left: 45px;
                      animation-delay: -1.2s;
                    }
                    .lds-grid div:nth-child(7) {
                      top: 45px;
                      left: 6px;
                      animation-delay: -0.8s;
                    }
                    .lds-grid div:nth-child(8) {
                      top: 45px;
                      left: 26px;
                      animation-delay: -1.2s;
                    }
                    .lds-grid div:nth-child(9) {
                      top: 45px;
                      left: 45px;
                      animation-delay: -1.6s;
                    }
                    @keyframes lds-grid {
                      0%, 100% {
                        opacity: 1;
                      }
                      50% {
                        opacity: 0.5;
                      }
                    } `,
        }}
      />

      <div className="lds-grid">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
