import logo from '../../../assets/logo-versnellingsplan.svg'
import App from '../../pages/App'
import Board from '../../pages/Board'
import { useStores } from '../../stores/'
import AlertMessage from '../common/AlertMessage'
import LangSwitch from '../common/LangSwitch'
import MainTargetsSideBar from '../common/MainTargetsSideBar'
import MenuButton from '../common/MenuButton'
import PopupMessage from '../common/PopupMessage'
import SideMenu from '../common/SideMenu'
import './Routing.scss'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { HashRouter as Router, Routes, Route, Link } from 'react-router-dom'
import { ErrorBoundary } from '../common/ErrorBoundary'
import LocalizedImage from '../common/LocalizedImage'

export default observer(function Routing() {
  const { commonStore } = useStores()

  //get fixed competence data here using translation file
  const { t, ready } = useTranslation(
    ['competencedata', 'common', 'translation'],
    {
      useSuspense: true,
    }
  )

  //tell WordPress context this app is running
  useEffect(() => {
    document.body.classList.add('dcp-app')

    return () => {
      document.body.classList.remove('dcp-app')
    }
  })

  useEffect(() => {
    if (ready) {
      //add fixed data to store for use in app
      commonStore.setStandardCompetences(
        t('competencedata:groups', {
          returnObjects: true,
        })
      )
      commonStore.setSectors(
        t('common:sectors', {
          returnObjects: true,
        })
      )
      commonStore.setEducationlevels(
        t('common:educationLevels', {
          returnObjects: true,
        })
      )
      commonStore.setLocaleData(
        t('translation:locales', { returnObjects: true })
      )
    }
  }, [t, ready, commonStore])

  useEffect(() => {
    document.title = t('common:title')
  }, [commonStore.lang, t])

  return (
    <ErrorBoundary>
      {ready && (
        <Router>
          <PopupMessage />
          <AlertMessage />

          <div className='wrapper'>
            <SideMenu />
            <div className='main'>
              <div
                className={
                  'header ' +
                  (commonStore.sideBarRightOpened ? 'sidebar-opened' : '')
                }
              >
                <LangSwitch />
                <MenuButton />
                {!commonStore.showSideMenu && (
                  <LocalizedImage
                    lang={commonStore.lang}
                    fallback={logo}
                    src='logo_alg.jpg'
                    className='App-logo'
                    alt='logo'
                  />
                )}
              </div>
              <div className='content'>
                <Routes>
                  <Route index element={<App />} />
                  <Route exact path='/' element={<App />} />
                  <Route exact path='/myboard/:boardid' element={<Board />} />
                  <Route path='/404' element={<NotFound />} />
                  <Route path='*' element={<NotFound />} />
                </Routes>
              </div>
            </div>
            <MainTargetsSideBar />
          </div>
        </Router>
      )}
    </ErrorBoundary>
  )
})

const NotFound = () => {
  return (
    <div className='row justify-content-center'>
      <div className='col-6 mt-4 text-center'>
        <h1>Helaas</h1>
        <p>Deze pagina bestaat niet</p>
        <p>
          <Link to={'/'}>Terug naar begin</Link>
        </p>
      </div>
    </div>
  )
}
