import React from 'react'
import { observer } from 'mobx-react'
import { useStores } from '../../stores/'

// You can think of these components as "pages"
// in your app.
import './AlertMessage.scss'
import { uniqueId } from '../../utils/uniqueId'

export default observer(function AlertMessage() {
  const { commonStore } = useStores()

  return (
    <React.Fragment>
      <div className='dcp-alert'>
        {commonStore.messageList.length > 0
          ? commonStore.messageList.map(function (item, i) {
              var { msg, type, persist = false } = item

              var timer = setTimeout(
                persist
                  ? null
                  : () => {
                      clearMessage()
                    },
                5000
              )

              var clearMessage = () => {
                clearInterval(timer)
                commonStore.clearMessage(i)
              }

              return (
                <div
                  key={uniqueId()}
                  className={'alert alert-' + (type || 'info')}
                  role='alert'
                >
                  {msg}
                  <button
                    type='button'
                    onClick={clearMessage}
                    className='btn-close'
                    aria-label='Close'
                  >
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
              )
            })
          : null}
      </div>
    </React.Fragment>
  )
})
