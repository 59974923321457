import { observer } from 'mobx-react'

import './Background.scss'

export default observer(function Background(props) {
  return (
    <div className={'background-container ' + props.className}>
      {props.children}
    </div>
  )
})
