import React from 'react'
import { useStores } from '../../stores'
import { uniqueId } from '../../utils/uniqueId'
import './MetaData.scss'

export const MetaData = ({ data, isDescription, t, color }) => {
  console.log('meta', data)
  const { commonStore } = useStores()
  let returnValue = []
  if (data) {
    if ('educationlevel' in data) {
      returnValue.push(
        <div className={'meta-data education-level ' + color} key={uniqueId()}>
          {commonStore.getEducationlevelById(data.educationlevel)?.title}
        </div>
      )
    }
    if ('sector' in data) {
      returnValue.push(
        <div className={'meta-data sector ' + color} key={uniqueId()}>
          {commonStore.getSectorById(data.sector)?.title}
        </div>
      )
    }
  }

  return returnValue
}
