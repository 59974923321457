import i18n from '../../js/i18n'

i18n.loadNamespaces(['main'], (err) => {})

export const copyUrlToClipboard = () => {
  navigator.clipboard.writeText(window.location.href).then(
    () => {
      /* clipboard successfully set */
      alert(i18n.t('main:message.copyUrl.success'))
    },
    () => {
      /* clipboard write failed */
      alert(i18n.t('main:message.copyUrl.fail'))
    }
  )
}
