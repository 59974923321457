import { useStores } from '../../stores/'
import { POPUP_TYPE } from '../../utils/Enums'
import { CloseButton } from './CloseButton'
import PopupCompetenceExample from './PopupCompetenceExample'
import './PopupMessage.scss'
import PopupNewBoard from './PopupNewBoard'
import PopupNewCompetence from './PopupNewCompetence'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect } from 'react'

export default observer(function PopupMessage() {
  const { popupMessageStore } = useStores()

  var closeMessage = useCallback(() => {
    popupMessageStore.clearPopupMessage()
  }, [popupMessageStore])

  return (
    <React.Fragment>
      {popupMessageStore.displayingPopupMessage && (
        <div className='popup-message-container'>
          <div
            className='popup-message col-sm-6 col-lg-4 float-none'
            role='alert'
          >
            <div className='row justify-content-center _margin-bottom-m'>
              <div className='col d-flex justify-content-end'>
                <CloseButton onClose={closeMessage.bind(this)} />
              </div>
            </div>
            <Message onClose={closeMessage.bind(this)} />
          </div>
        </div>
      )}
    </React.Fragment>
  )
})

const Message = (onClose) => {
  const { popupMessageStore } = useStores()

  var msg = null

  switch (popupMessageStore.popupType) {
    case POPUP_TYPE.NEW_BOARD:
      msg = (
        <PopupNewBoard onclose={onClose} data={popupMessageStore.nextMessage} />
      )
      break
    case POPUP_TYPE.NEW_COMPETENCE:
    case POPUP_TYPE.EDIT_COMPETENCE:
      msg = (
        <PopupNewCompetence
          onclose={onClose}
          data={popupMessageStore.nextMessage}
        />
      )
      break
    case POPUP_TYPE.NEW_COMPETENCE_EXAMPLE:
    case POPUP_TYPE.EDIT_COMPETENCE_EXAMPLE:
      msg = (
        <PopupCompetenceExample
          onclose={onClose}
          data={popupMessageStore.nextMessage}
        />
      )
      break
    default:
      msg = <p>unknown popup</p>
  }

  return msg
}
