import addBoard from '../../assets/icon-arrow-right-white.svg'
import pointer1 from '../../assets/icon-scan-mind.svg'
import pointer2 from '../../assets/icon-lijstje.svg'
import pointer3 from '../../assets/icon-puzzelstukjes.svg'
import { Page } from '../containers/Page'
import { useStores } from '../stores'
import { POPUP_TYPE } from '../utils/Enums'
import './App.scss'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export default observer(function App() {
  const { t, ready } = useTranslation(['main'], {
    useSuspense: true,
  })
  const { popupMessageStore, commonStore } = useStores()

  useEffect(() => {
    commonStore.setSideBarItems(true)
    commonStore.setSideBarOpen(true)
    commonStore.setShowSideBarC2a(true)
    commonStore.setSideMenuShow(false)
    commonStore.setSideMenuOpen(false)
    commonStore.setLangSelectOpened(false)

    return () => {
      commonStore.setSideBarItems(true)
      commonStore.setShowSideBarC2a(false)
      commonStore.setSideBarOpen(false)
      commonStore.setSideMenuShow(true)
      commonStore.setLangSelectOpened(false)
    }
  }, [commonStore])

  const onNewBoard = useCallback(() => {
    popupMessageStore.showPopupMessage({
      type: POPUP_TYPE.NEW_BOARD,
      description: 'new board popup',
    })
  }, [popupMessageStore])

  return (
    ready && (
      <Page bgClassName='background-main'>
        <div className='col-3'></div>
        <div className='c2a-container col-8'>
          <div className='row'>
            <div className='col'>
              <div
                className='c2a-container-title'
                dangerouslySetInnerHTML={{
                  __html: t('title'),
                }}
              ></div>
              <div
                className='c2a-container-subtitle'
                dangerouslySetInnerHTML={{
                  __html: t('subtitle'),
                }}
              ></div>
              <div
                className='c2a-button'
                role='button'
                onClick={onNewBoard.bind(this)}
              >
                <div className='c2a-background'></div>
                <div
                  className='c2a-label'
                  dangerouslySetInnerHTML={{
                    __html: t('message.c2a'),
                  }}
                ></div>
                <img className='c2a-icon' alt='' src={addBoard} />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-5'>
              <div className='c2a-pointer'>
                <img src={pointer1} alt='' />
                <div className='c2a-pointer-label'>
                  {t('message.pointers.scan')}
                </div>
              </div>
              <div className='c2a-pointer'>
                <img src={pointer2} alt='' />
                <div className='c2a-pointer-label'>
                  {t('message.pointers.inventorize')}
                </div>
              </div>
              <div className='c2a-pointer'>
                <img src={pointer3} alt='' />
                <div className='c2a-pointer-label'>
                  {t('message.pointers.integrate')}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='col-2 border'></div> */}
      </Page>
    )
  )
})
